/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Col, Row, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Notification } from 'components/common/Notification';
import { Icon } from 'components/common/Icon';
import { isEqual, size } from 'lodash';
import { Loader } from 'components/common/Loader';
import CodeMirror from '@uiw/react-codemirror';
import { linter } from '@codemirror/lint';
import { json, jsonParseLinter } from '@codemirror/lang-json';
import customTheme from 'theme/codemirror-themes/customTheme';
import { updateRulesDetails, updateStatusOfDeployBtn } from 'store/slices/property';
import { propertyAPI } from 'services';
// import TestComp from './TestComp';
import styles from './ProjectOriginRulesView.module.scss';

export const ProjectOriginRulesView: FC = () => {
  const dispatch = useAppDispatch();
  const { directoryName } = useParams<{ directoryName?: string }>();
  const { token } = useAppSelector(({ auth }) => auth);
  const { rules } = useAppSelector(({ property }) => property);
  const [rulesData, setRulesData] = useState<[]>(rules);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { data: propertyDetails, isLoading: isPropertyLoading } = propertyAPI.useFetchPropertyQuery(
    String(directoryName),
    {
      skip: !token,
    }
  );

  useEffect(() => {
    dispatch(updateStatusOfDeployBtn(isError));
  }, [dispatch, isError]);

  useEffect(() => {
    setRulesData((prev) => {
      if (isEqual(prev, rules)) {
        return prev;
      }
      return rules;
    });
  }, [rules, propertyDetails?.data?.rules]);

  useEffect(() => {
    dispatch(updateRulesDetails(rulesData));
  }, [dispatch, isError, rulesData]);

  // useEffect(() => {
  //   if (data) {
  //     setValues(data.data);
  //   }
  // }, [data]);
  // const parseJSON = (json: any): any | null => {
  //   if (Array.isArray(json)) {
  //     return json.map((element) => parseJSON(element));
  //   }
  //   if (typeof json === 'object') {
  //     if (json.hasOwnProperty('if')) {
  //       const condition = json['if'][0];
  //       const action = json['if'][1];

  //       return {
  //         condition,
  //         action,
  //       };
  //     }
  //     return {
  //       condition: null,
  //       action: null,
  //     };
  //   }
  //   return null;
  // };

  const handleChange = useCallback((value: string) => {
    try {
      setRulesData(JSON.parse(value));
      setIsError(false);
      setErrorMessage('');
    } catch (e: any) {
      setIsError(true);
      setErrorMessage(e.message);
    }
  }, []);

  return (
    <Row gutter={[20, 20]} className="ProjectOriginRulesView">
      <Col span={24}>
        <div>
          <Row className={styles.row} align="middle" gutter={16}>
            {isError && (
              <Col className={styles.errorTextBlcok} span={24}>
                <span className={styles.errorText}>{errorMessage}</span>
              </Col>
            )}
            <span className={styles.switchText}>
              Here you can define conditional behavior to be applied at the edge based on the
              properties of each request.
            </span>
          </Row>

          <Row className={styles.codemirrorWrapper} align="middle" gutter={16}>
            <Col
              className={styles.jsonViewer}
              span={24}
              style={{
                padding: '0px',
                position: 'relative',
                border: '1px solid lightgray',
                borderRadius: 5,
                minHeight: '40vh',
                maxHeight: '70vh',
                overflow: 'auto',
              }}>
              {isPropertyLoading ? (
                <Row justify="center" style={{ height: '40vh' }} align="middle">
                  <Loader />
                </Row>
              ) : (
                <CodeMirror
                  theme={customTheme}
                  value={JSON.stringify(rulesData, undefined, 2)}
                  indentWithTab
                  className={styles.jsonWrapper}
                  height="100%"
                  extensions={[customTheme, json(), linter(jsonParseLinter())]}
                  onChange={handleChange}
                  readOnly
                  editable={false}
                />
              )}
            </Col>

            {size(rulesData) ? (
              <div style={{ position: 'absolute', top: 55, right: 25 }}>
                <Tooltip placement="top" title="Copy to clipboard">
                  <CopyToClipboard
                    text={JSON.stringify(rulesData, undefined, 2)}
                    onCopy={() => Notification({ title: 'Successfuly copied to clipboard' })}>
                    <Icon className={styles.copyRules} name="copy" style={{ fontSize: 20 }} />
                  </CopyToClipboard>
                </Tooltip>
              </div>
            ) : null}
          </Row>
        </div>
      </Col>
    </Row>

    // <Row gutter={[20, 20]} className="ProjectOriginRulesView">
    //   {/* {!data || isDataLoading ? (
    //     <> */}
    // {/* <Col span={24}>
    //   <BlockWrapper minHeight="initial" padding="16px 24px" height={132}>
    //     <Skeleton active paragraph={{ rows: 1 }} />
    //   </BlockWrapper>
    // </Col> */}
    //   <Col span={24}>
    //     <BlockWrapper title="Rules" padding="16px 24px">
    // <Row className={styles.row} align="middle" gutter={16}>
    //   <Col span={24}>
    //     <span className={styles.switchText}>
    //       Here you can define conditional behavior to be applied at the edge based on the
    //       properties of each request.
    //     </span>
    //   </Col>
    // </Row>
    // <Row className={styles.row} align="middle" gutter={16}>
    //   <Col span={24}>
    //     <TestComp />
    //   </Col>
    // </Row>
    //     </BlockWrapper>
    //   </Col>
    //   {/* <Col span={24}>
    //         <BlockWrapper minHeight="initial" padding="16px 24px" height={132}>
    //           <Skeleton active paragraph={{ rows: 1 }} />
    //         </BlockWrapper>
    //       </Col> */}
    //   {/* </>
    //   ) : (
    //     map(values, ({ id, name, description, selected, notes }, idx) => {
    //       if (name === 'Force HTTPS Connections') return null;
    //       if (name === 'Dynamic Cache' && projectDetails?.application_type_id !== 1) return null;
    //       return (
    //         <Col span={24} key={id}>
    //           <BlockWrapper
    //             minHeight="initial"
    //             padding="16px 24px"
    //             title={name}
    //             isSkeleton={!data || isDataLoading}>
    //             <Row className={styles.row} align="middle" gutter={16}>
    //               <Col>
    //                 <span className={styles.switchText}>{description}</span>
    //               </Col>
    //               <Col>
    //                 <Switch
    //                   checked={selected}
    //                   onChange={(val) => {
    //                     if (values) {
    //                       const copied = cloneDeep(values);
    //                       const maped = map(copied, (item) => ({ ...item, selected: false }));
    //                       maped[idx].selected = val;
    //                       // if (!isNil(dependencies)) {
    //                       //   forEach(dependencies.needed, (neededId) => {
    //                       //     const depsItemIdx = findIndex(
    //                       //       values,
    //                       //       ({ id: ruleId }) => ruleId === neededId
    //                       //     );
    //                       //     if (depsItemIdx && val) {
    //                       //       const depsItem = copied[depsItemIdx];
    //                       //       depsItem.selected = true;
    //                       //       copied[depsItemIdx] = depsItem;
    //                       //     }
    //                       //   });
    //                       // }
    //                       setValues([...maped]);
    //                     }
    //                   }}
    //                 />
    //               </Col>
    //             </Row>
    //             <Row align="middle" gutter={2}>
    //               <Col>
    //                 <span className={classNames(styles.boldText)}>{notes}</span>
    //               </Col>
    //             </Row>
    //           </BlockWrapper>
    //         </Col>
    //       );
    //     })
    //   )}
    //   <Col span={24}>
    //     <Row justify="end">
    //       <Col>
    //         {!data || isDataLoading ? (
    //           <Skeleton.Button active />
    //         ) : (
    //           <Button onClick={onSaveClick} loading={isLoading}>
    //             Save
    //           </Button>
    //         )}
    //       </Col>
    //     </Row>
    //   </Col> */}
    // </Row>
  );
};

export default ProjectOriginRulesView;
