/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Row, Table, Tooltip } from 'antd';
// import { UserOutlined } from '@ant-design/icons';
import type { TableColumnsType } from 'antd';
// import { Notification } from 'components/common/Notification';
import { Icon } from 'components/common/Icon';
import Dropdown from 'components/common/Dropdown';
import Button from 'components/common/Button';
import { Label } from 'components/common/Label';
import { Loader } from 'components/common/Loader';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { deplymentServiceAPIv2 } from 'services';
import moment from 'moment';
import { IDeployment } from 'models/deployments';
import { useAppSelector } from 'store';
import RouteTypes from 'router/types';
import { timeAgo } from 'helpers/utils';
import { size } from 'lodash';
import { Divider } from '../../../../common/Divider/Divider';
import './ProjectOriginDeployments.scss';

interface DataType {
  key?: React.Key;
  name?: string;
  age?: number;
  order?: any;
  address?: string;
  deployed?: any;
  by?: any;
  environment?: any;
  url?: any;
  notes?: string;
  menu?: any;
}

export const ProjectOriginDeployments = () => {
  const { directoryName } = useParams<{ directoryName?: string }>();
  const { token } = useAppSelector(({ auth }) => auth);
  const { dns_domain_name, status } = useAppSelector(({ property }) => property);
  const navigate = useNavigate();

  const [numberOfSelectedRows, setNumberOfSelectedRows] = useState(0);

  // const [pollingIntervalTime, setPollingIntervalTime] = useState(0);
  // const getPollingInterval = (time: number) => setPollingIntervalTime(time);

  const renderDropdownTitleContent = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Button type="subtle" disabled={!numberOfSelectedRows} className="additionalBtn">
        <span className="deleteBtn-color">
          {numberOfSelectedRows ? `Delete (${numberOfSelectedRows})` : 'Delete'}
        </span>
      </Button>
    </div>
  );

  // const by = (picture: string, name: string) => {
  //   const qwe = (
  //     <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
  //       {name}
  //       <Button
  //         type="subtle"
  //         onClick={() => {
  //           Notification({ type: 'check', title: 'Copied' });
  //         }}
  //         size="big"
  //         icon="copy"
  //         className="copyIcon"
  //       />
  //     </div>
  //   );
  //   return (
  //     <div style={{ cursor: 'pointer' }}>
  //       <Tooltip title={qwe} placement="bottom">
  //         <Avatar size={28} src={picture} icon={<UserOutlined />} />
  //       </Tooltip>
  //     </div>
  //   );
  // };

  const getStatus = (color: string, time: string) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Tooltip title="completed" placement="bottom">
          <span
            style={{
              display: 'block',
              position: 'relative',
              borderRadius: '50%',
              backgroundColor: color,
              width: '12px',
              height: '12px',
              marginRight: '5px',
            }}
          />
        </Tooltip>

        <Tooltip title={moment(time).format('MMMM Do YYYY, HH:mm:ss')}>
          <span style={{ whiteSpace: 'nowrap' }}>{timeAgo(time)}</span>
        </Tooltip>
      </div>
    );
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`);
      // console.log('selectedRows: ', selectedRows);
      setNumberOfSelectedRows(size(selectedRows));
    },
    // getCheckboxProps: (record: DataType) => {
    //   console.log('getCheckboxProps', record);
    //   return {
    //     disabled: record.name === 'Disabled User', // Column configuration not to be checked
    //     name: record.name,
    //   };
    // },
    // onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
    //   console.log('onSelectAll_selected', selected);
    //   console.log('onSelectAll_selectedRows', selectedRows);
    //   setNumberOfSelectedRows(size(selectedRows));
    //   console.log('onSelectAll_changeRows', changeRows);
    // },
    // onSelect: (selected: any, selectedRows: any, changeRows: any, nativeEvent: any) => {
    //   console.log('onSelect_selected', selected);
    //   console.log('onSelect_selectedRows', selectedRows);
    //   console.log('onSelect_changeRows', changeRows);
    //   console.log('onSelect_nativeEvent', nativeEvent);
    // },
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: '#',
      dataIndex: 'order',
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      // render: (text: string) => <Button type="subtle">{text}</Button>,
      width: '4%',
    },
    {
      title: 'Deployed',
      dataIndex: 'deployed',
      width: '25%',
    },
    // {
    //   title: 'By',
    //   dataIndex: 'by',
    // },
    // {
    //   title: 'Environment',
    //   dataIndex: 'environment',
    // },
    {
      title: 'URL',
      dataIndex: 'url',
      width: '65%',
      fixed: false,
    },
    // {
    //   title: 'Notes',
    //   dataIndex: 'notes',
    // },
    {
      title: (
        <Dropdown
          align={{ targetOffset: [25, 10] }}
          className="detailsHeaderDots"
          placement="bottom"
          triggerElement={
            <Icon
              name="action-vertical"
              className={numberOfSelectedRows > 0 ? 'dotsIcon' : 'dotsIconDisabled'}
            />
          }>
          {numberOfSelectedRows > 0 ? renderDropdownTitleContent() : null}
        </Dropdown>
      ),
      dataIndex: 'menu',
      fixed: 'right',
      width: '1%',
    },
  ];

  const renderDropdownContent = (id: string, index: number) => {
    const handleDetailsClick = () => {
      navigate(
        generatePath(RouteTypes.settingsDeploymentsDetails, {
          directoryName: String(directoryName),
          deploymentId: String(id),
        })
      );
    };

    return (
      <div className="deploymentsDetailsBtns">
        <Button className="additionalBtn" type="subtle" onClick={handleDetailsClick}>
          <span className="detailsBtn-color">Details</span>
        </Button>
        <Button className="additionalBtn" type="subtle">
          <span className="redeployBtn-color">{index === 0 ? 'Redeploy' : 'Rollback'}</span>
        </Button>
        <Divider style={{ margin: '8px 0' }} />
        <Button disabled={index === 0} className="additionalBtn" type="subtle">
          <span className="deleteBtn-color">Delete</span>
        </Button>
      </div>
    );
  };

  const { data: deployments, isLoading } = deplymentServiceAPIv2.useFetchAllDeploymentsQuery(
    String(directoryName),
    {
      skip: !token,
      pollingInterval: status === 'deploying' ? 5000 : 0,
    }
  );

  const lastCompletedDeploymentIdx = deployments?.data.deployments
    .map((item) => item.status)
    .indexOf('completed');

  const normalizedData = deployments?.data.deployments.map((item: IDeployment, idx: number) => {
    const configData = JSON.parse(item.configuration_data);
    const { hostnames } = configData;

    const curColor = () => {
      if (item.status === 'completed') {
        return 'green';
      }
      if (item.status === 'failed') {
        return 'red';
      }
      return 'yellow';
    };

    const getEdgeLabel = () => {
      if (idx === lastCompletedDeploymentIdx) {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <Label type="success">edge</Label>

            {size(hostnames) > 0 ? (
              <a href={`https://${hostnames[0].hostname ?? ''}`} target="_blank" rel="noreferrer">
                <span style={{ fontSize: 13, padding: '0px 3px' }}>
                  {hostnames[0].hostname ?? ''}
                </span>
              </a>
            ) : (
              <a href={`https://${dns_domain_name ?? ''}`} target="_blank" rel="noreferrer">
                <span style={{ fontSize: 13, padding: '0px 3px' }}>{dns_domain_name ?? ''}</span>
              </a>
            )}
          </div>
        );
      }
      return null;
    };

    const orderButton = (id: string, qnt: number, index: number) => {
      const handleDetailsClick = () => {
        navigate(
          generatePath(RouteTypes.settingsDeploymentsDetails, {
            directoryName: String(directoryName),
            deploymentId: String(id),
          })
        );
      };
      return (
        <Button onClick={handleDetailsClick} type="subtle">
          <span className="orderBtn">#{qnt - index}</span>
        </Button>
      );
    };

    return {
      ...item,
      key: item.id,
      order: orderButton(item.id, size(deployments?.data.deployments), idx),
      deployed: getStatus(curColor(), item.created_at),
      // by: by('', 'John Doe'),
      // environment: (
      //   <div
      //     style={{
      //       display: 'flex',
      //       flexDirection: 'row',
      //       alignItems: 'center',
      //       whiteSpace: 'nowrap',
      //     }}>
      //     <a href="https://example.com">Prod (v1)</a>
      //     {/* <Icon name="diff" className="diffIcon" /> */}
      //   </div>
      // ),
      url: getEdgeLabel(),
      // notes: 'Soasdme notes',
      menu: (
        <Dropdown
          // withArrow
          className="moreDetails"
          align={{ targetOffset: [25, 10] }}
          placement="bottom"
          triggerElement={<Icon name="action-vertical" className="dotsIcon" />}>
          {renderDropdownContent(item.id, idx)}
        </Dropdown>
      ),
      // created_at: "2024-07-01T20:47:16.000000Z"
      // id: "85f7d2db-3891-4ab0-91ea-e5943dfec825"
      // status: "completed"
    };
  });

  return (
    <div className="ProjectOriginCnames">
      <div>
        <p style={{ fontSize: 14, fontWeight: 600 }}>
          Here you can view the history of changes deployed to this environment.
        </p>
      </div>

      <div className="deploymentsTable">
        <Table
          size="small"
          loading={{
            spinning: isLoading,
            indicator: (
              <Row justify="center" align="middle">
                <Loader />
              </Row>
            ),
          }}
          onChange={(pagination, filters, sorter, extra) => {
            console.log('params', pagination, filters, sorter, extra);
          }}
          getPopupContainer={() => document.querySelector('.deploymentsTable') as HTMLElement}
          // pagination={{ position: ['bottomRight'], size: 'small' }}
          pagination={false}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          columns={columns}
          dataSource={normalizedData}
        />
      </div>
    </div>
  );
};

export default ProjectOriginDeployments;
