/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-nested-ternary */
import React, { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { HeaderSuperAdmin } from 'components/layouts/HeaderSuperAdmin';
import { Col, Layout, Row } from 'antd';
import { generatePath, Link, matchRoutes, NavLink, useLocation, useParams } from 'react-router-dom';
import cn from 'classnames';
import * as Sentry from '@sentry/browser';
import { Icon, IconType } from 'components/common/Icon';
import { useAppDispatch, useAppSelector } from 'store';
import { isNil, map } from 'lodash';
import useHistoryHook from 'hooks/useHistoryHook';
import Dropdown from 'components/common/Dropdown';
import RouteTypes from 'router/types';
import { toggleMenu, updatePageName } from 'store/slices/ui';
import { propertyAPI, userDetailsApi } from 'services';
import { ShowUserPayload } from 'models';
import { useAuth0 } from '@auth0/auth0-react';
import { MainLayoutSubroute } from './MainLayoutSubroute';
// import { MainLayoutProjectDropdown } from './MainLayoutProjectDropdown';
import styles from './MainLayout.module.scss';

const LINKS = {
  helpCenter: 'https://docs.edgeport.com',
  statusCenter: 'https://status.edgeport.com',
  termsOfUse: 'https://www.edgeport.com/terms-of-service',
  privacyPolicy: 'https://www.edgeport.com/privacy',
};

const { Content, Sider } = Layout;
interface Props {
  children?: ReactNode;
}

const routes = [
  { path: RouteTypes.home },
  { path: RouteTypes.create },
  { path: RouteTypes.project },
  { path: RouteTypes.details },
  { path: RouteTypes.routing },
  { path: RouteTypes.logs },
  { path: RouteTypes.security },
  { path: RouteTypes.settingsSecurity },
  { path: RouteTypes.settings },
  { path: RouteTypes.engine },
  { path: RouteTypes.engineDraft },
  { path: RouteTypes.engineHistory },
  { path: RouteTypes.userSettings },
  { path: RouteTypes.settingsUser },
  { path: RouteTypes.billingReports },
  { path: RouteTypes.billing },
  { path: RouteTypes.invoices },
  { path: RouteTypes.settingsDeployments },
  { path: RouteTypes.settingsDeploymentsDetails },
  { path: RouteTypes.settingsHostnames },
  { path: RouteTypes.settingsService },
  { path: RouteTypes.settingsCollaboration },
  { path: RouteTypes.settingsRules },
  { path: RouteTypes.settingsCache },
  { path: RouteTypes.settingsTLS },
  { path: RouteTypes.settingsAccess },
];

export const MainLayout: FC<Props> = ({ children }) => {
  const { logout } = useAuth0();

  const dispatch = useAppDispatch();

  const { previousLocation } = useHistoryHook();
  const { token } = useAppSelector(({ auth }) => auth);
  const { isMenuColapsed } = useAppSelector(({ ui }) => ui);
  const { directoryName } = useParams<{ directoryName?: string }>();
  const location = useLocation();
  const route = matchRoutes(routes, location.pathname);
  const { data } = userDetailsApi.useFetchMeDetailsQuery('', {
    skip: !token,
  });

  useEffect(() => {
    if (data?.success) {
      const { details, email, id } = data?.data as ShowUserPayload;
      if (details) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { first_name, last_name, company_name } = details;
        Sentry.setUser({
          id: id && id,
          username: `${first_name && first_name} ${last_name && last_name} - ${
            company_name && company_name
          }`,
          email: email && email,
        });
      } else {
        Sentry.setUser({
          id: id && id,
          email: email && email,
        });
      }
    }
  }, [data]);

  const isHome = useMemo(() => {
    if (isNil(route)) return false;
    return route[0].route.path === RouteTypes.home;
  }, [route]);
  const isCreateProperty = useMemo(() => {
    if (isNil(route)) return false;
    return route[0].route.path === RouteTypes.create;
  }, [route]);

  const isUserSettings = useMemo(() => {
    if (isNil(route)) return false;
    return (
      route[0].route.path === RouteTypes.userSettings ||
      route[0].route.path === RouteTypes.settingsUser ||
      route[0].route.path === RouteTypes.billing ||
      route[0].route.path === RouteTypes.invoices ||
      route[0].route.path === RouteTypes.billingReports
    );
  }, [route]);

  const navMenu = useMemo((): {
    to: string;
    name: string;
    end?: boolean;
    icon: IconType;
    subrouts?: {
      to: string;
      name: string;
      end?: boolean;
    }[];
  }[] => {
    return [
      {
        to: RouteTypes.project,
        name: 'Overview',
        end: true,
        icon: 'overview',
      },
      {
        to: RouteTypes.security,
        name: 'Security',
        icon: 'shield',
      },
      {
        to: RouteTypes.logs,
        name: 'Logs',
        icon: 'logsIcon',
      },
      {
        name: 'Deployments',
        icon: 'deployment',
        to: RouteTypes.settingsDeployments,
      },
      {
        name: 'Origins',
        icon: 'origins',
        to: RouteTypes.settings,
        end: true,
      },
      {
        name: 'Rules',
        icon: 'rules',
        to: RouteTypes.settingsRules,
      },
      {
        name: 'Hostnames',
        icon: 'language',
        to: RouteTypes.settingsHostnames,
      },
      {
        name: 'Security Profiles',
        icon: 'security',
        to: RouteTypes.settingsSecurity,
      },
      {
        name: 'TLS Certificate',
        icon: 'ssl',
        to: RouteTypes.settingsTLS,
      },
      {
        name: 'Collaboration',
        icon: 'collaboration',
        to: RouteTypes.settingsCollaboration,
      },
      {
        name: 'Access Control',
        icon: 'key',
        to: RouteTypes.settingsAccess,
      },
      {
        name: 'Settings',
        icon: 'settings',
        to: RouteTypes.settingsService,
      },
    ];
  }, []);
  const navMenuWithRouting = useMemo((): {
    to: string;
    name: string;
    end?: boolean;
    icon: IconType;
    subrouts?: {
      to: string;
      name: string;
      end?: boolean;
    }[];
  }[] => {
    return [
      {
        to: RouteTypes.project,
        name: 'Overview',
        end: true,
        icon: 'overview',
      },
      {
        to: RouteTypes.security,
        name: 'Security',
        icon: 'shield',
      },
      {
        to: RouteTypes.routing,
        name: 'Routing',
        icon: 'routing',
      },
      {
        to: RouteTypes.logs,
        name: 'Logs',
        icon: 'logsIcon',
      },
      {
        name: 'Deployments',
        icon: 'deployment',
        to: RouteTypes.settingsDeployments,
      },
      {
        name: 'Origins',
        icon: 'origins',
        to: RouteTypes.settings,
        end: true,
      },
      {
        name: 'Rules',
        icon: 'rules',
        to: RouteTypes.settingsRules,
      },
      {
        name: 'Hostnames',
        icon: 'language',
        to: RouteTypes.settingsHostnames,
      },
      {
        name: 'Security Profiles',
        icon: 'security',
        to: RouteTypes.settingsSecurity,
      },
      {
        name: 'TLS Certificate',
        icon: 'ssl',
        to: RouteTypes.settingsTLS,
      },
      {
        name: 'Collaboration',
        icon: 'collaboration',
        to: RouteTypes.settingsCollaboration,
      },
      {
        name: 'Access Control',
        icon: 'key',
        to: RouteTypes.settingsAccess,
      },
      {
        name: 'Settings',
        icon: 'settings',
        to: RouteTypes.settingsService,
      },
    ];
  }, []);

  const currentNavMenu = useMemo(() => {
    if (data?.data?.dns === 1) {
      return navMenuWithRouting;
    }
    return navMenu;
  }, [data?.data?.dns, navMenu, navMenuWithRouting]);

  const {
    data: properties,
    // refetch,
    // isLoading: isLoadingProperties,
  } = propertyAPI.useFetchAllPropertiesQuery('', {
    skip: !token,
    // pollingInterval: isPolling ? 10000 : 0,
  });

  const navPropertiesMenu = useMemo((): {
    to: string | RouteTypes;
    name: string;
    id: string;
    end?: boolean;
    icon: IconType;
  }[] => {
    return properties?.data?.properties?.map((item: any) => ({
      to: RouteTypes.project,
      name: item.name,
      icon: 'property',
      id: item.id,
    }));
  }, [properties?.data?.properties]);

  const userSettingsNavMenu = useMemo((): {
    to: string;
    name: string;
    end?: boolean;
    icon: IconType;
    subrouts?: {
      to: string;
      name: string;
      end?: boolean;
      icon?: IconType;
    }[];
  }[] => {
    if (data?.data?.billable !== 0) {
      return [
        {
          to: RouteTypes.userSettings,
          name: 'Settings',
          end: true,
          icon: 'settings',
        },
        {
          to: RouteTypes.billing,
          name: 'Billing',
          icon: 'set-billing',
          end: true,
          subrouts: [
            {
              to: RouteTypes.billing,
              name: 'Details',
              icon: 'set-details',
              end: true,
            },
            {
              to: RouteTypes.invoices,
              name: 'Invoices',
              icon: 'set-invoice',
            },
            {
              to: RouteTypes.billingReports,
              name: 'Reports',
              icon: 'set-reports',
            },
          ],
        },
      ];
    }
    return [
      {
        to: RouteTypes.userSettings,
        name: 'Settings',
        end: true,
        icon: 'settings',
      },
      {
        to: RouteTypes.invoices,
        name: 'Invoices',
        icon: 'set-invoice',
      },
      {
        to: RouteTypes.billingReports,
        name: 'Billing Reports',
        icon: 'set-reports',
      },
    ];
  }, [data?.data?.billable]);

  const isAdminHeaderDisplayed = data?.data?.is_acting_as?.status;

  const isLoadingNewProperty = true;

  const renderHeaderSuperAdmin = useMemo(() => {
    return <HeaderSuperAdmin userData={data?.data as ShowUserPayload} />;
  }, [data?.data]);

  const currentRoute = RouteTypes.project;
  const handleOpenLink = (link: string) => window.open(link);

  const propertyName = useMemo(
    () => navPropertiesMenu?.filter((i) => i.id === directoryName)[0]?.name || '',
    [directoryName, navPropertiesMenu]
  );

  useEffect(() => {
    const name =
      currentNavMenu?.filter((i) => i.to === [...(route || [])][0].route.path)[0]?.name || '';
    dispatch(updatePageName(name));
  }, [currentNavMenu, dispatch, route]);

  // const pageNameNav = useMemo(() => {
  //   const name =
  //     currentNavMenu?.filter((i) => i.to === [...(route || [])][0].route.path)[0]?.name || '';
  //   dispatch(updatePageName(name));

  //   return name;
  // }, [currentNavMenu, dispatch, route]);

  const findPageName = useCallback(
    (menuItems: typeof userSettingsNavMenu, cRoute: string): string => {
      const foundItem = menuItems.find(
        (item) => item.to === cRoute || item.subrouts?.find((subroute) => subroute.to === cRoute)
      );

      if (foundItem) {
        if (foundItem.to === cRoute) {
          return foundItem.name;
        }

        const foundSubroute = foundItem.subrouts?.find((subroute) => subroute.to === cRoute);
        return foundSubroute ? foundSubroute.name : '';
      }
      return '';
    },
    []
  );

  const pageNameSettings = useMemo(() => {
    const curRoute = [...(route || [])][0]?.route?.path || '';
    return findPageName(userSettingsNavMenu, curRoute);
  }, [findPageName, route, userSettingsNavMenu]);

  return (
    <Layout className={styles.mainLayout}>
      {isAdminHeaderDisplayed && renderHeaderSuperAdmin}

      <Layout hasSider>
        <div
          className={styles.leftBar}
          style={{ width: isHome || isCreateProperty ? '150px' : '48px' }}>
          <Link to={isHome ? previousLocation : RouteTypes.home} className={styles.logo}>
            {isHome || isCreateProperty ? (
              <Icon name="logo-full" className={styles.logoFull} />
            ) : (
              <Icon name="logo-small" className={styles.logoSmall} />
            )}
          </Link>

          <Sider
            trigger={null}
            width={isHome || isCreateProperty ? 150 : 48}
            collapsedWidth={isHome || isCreateProperty ? 150 : 48}
            collapsible
            collapsed={isMenuColapsed}
            theme="light"
            style={{
              position: 'fixed',
              left: 0,
              top: isAdminHeaderDisplayed ? '118px' : '58px',
              bottom: 0,
              height: '100vh',
              zIndex: 0,
              backgroundColor: '#E8F1F8',
              // padding: '0px 8px',
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100% - 60px)',
                justifyContent: 'space-between',
                overflow: 'auto',
              }}
              className={cn(
                styles.nav
                //  { [styles.collapsed]: isMenuColapsed }
              )}>
              <Row>
                <Col key="home" span={24}>
                  <div style={{ padding: '5px 8px' }}>
                    {isHome || isCreateProperty ? (
                      <NavLink
                        to={generatePath(RouteTypes.home, {
                          directoryName: '',
                        })}
                        className={() =>
                          // { isActive }
                          cn(styles.routeLeftBar, {
                            [styles.activeLeftMenu]: true,
                          })
                        }>
                        <Icon name="language" className={styles.routeIconLeftMenu} />
                        <div className={styles.textWrapper} style={{ overflow: 'elipsis' }}>
                          Web Properties
                        </div>
                      </NavLink>
                    ) : (
                      <Dropdown
                        isSmall
                        placement="right"
                        trigger={['hover']}
                        triggerElement={
                          <NavLink
                            to={generatePath(RouteTypes.home, {
                              directoryName: '',
                            })}
                            end
                            className={() =>
                              // { isActive }
                              cn(styles.routeLeftBar, {
                                [styles.activeLeftMenu]: true,
                              })
                            }>
                            <Icon name="language" className={styles.routeIconLeftMenu} />
                          </NavLink>
                        }>
                        <span className={styles.dropdownText}>Web Properties</span>
                      </Dropdown>
                    )}
                  </div>
                </Col>
                <Col key="create" span={24}>
                  <div style={{ padding: '5px 8px' }}>
                    {isHome || isCreateProperty ? (
                      <NavLink
                        to={generatePath(RouteTypes.create, {
                          directoryName: '',
                        })}
                        // to={RouteTypes.create}
                        className={({ isActive }) =>
                          cn(styles.routeLeftBar, {
                            [styles.activeLeftMenu]: (!isHome || !isCreateProperty) && isActive,
                          })
                        }>
                        <Icon name="plus" className={styles.routeIconLeftMenu} />
                        <div className={styles.textWrapper} style={{ overflow: 'elipsis' }}>
                          Create Property
                        </div>
                      </NavLink>
                    ) : (
                      <Dropdown
                        isSmall
                        placement="right"
                        trigger={['hover']}
                        triggerElement={
                          <NavLink
                            to={generatePath(RouteTypes.create, {
                              directoryName: '',
                            })}
                            end
                            className={({ isActive }) =>
                              cn(styles.routeLeftBar, {
                                [styles.activeLeftMenu]: (!isHome || !isCreateProperty) && isActive,
                              })
                            }>
                            <Icon name="plus" className={styles.routeIconLeftMenu} />
                          </NavLink>
                        }>
                        <span className={styles.dropdownText}>Create Property</span>
                      </Dropdown>
                    )}
                  </div>
                </Col>
                {map(navPropertiesMenu, ({ name, to, end, icon, id }) => (
                  <Col key={id} span={24}>
                    <div style={{ padding: '5px 8px' }}>
                      {isHome || isCreateProperty ? (
                        <NavLink
                          // onClick={() => currentProperty(name)}
                          to={generatePath(to, { directoryName: id || '' })}
                          className={({ isActive }) =>
                            cn(styles.routeLeftBar, {
                              [styles.activeLeftMenu]: !isUserSettings && isActive,
                            })
                          }>
                          <Icon name={icon} className={styles.routeIconLeftMenu} />
                          <div className={styles.textWrapper} style={{ overflow: 'elipsis' }}>
                            {name}
                          </div>
                        </NavLink>
                      ) : (
                        <Dropdown
                          isSmall
                          placement="right"
                          trigger={['hover']}
                          triggerElement={
                            <NavLink
                              // onClick={() => currentProperty(name)}
                              to={
                                isHome || isUserSettings
                                  ? generatePath(to, { directoryName: id || '' })
                                  : generatePath(currentRoute as string, {
                                      directoryName: id,
                                    })
                              }
                              end={end}
                              className={({ isActive }) =>
                                cn(styles.routeLeftBar, {
                                  [styles.activeLeftMenu]: !isUserSettings && isActive,
                                })
                              }>
                              <Icon name={icon} className={styles.routeIconLeftMenu} />
                            </NavLink>
                          }>
                          <span className={styles.dropdownText}>{name}</span>
                        </Dropdown>
                      )}
                    </div>
                  </Col>
                ))}
              </Row>

              <Row className={cn(styles.bottomMenu)}>
                <Col key="myAccount" span={24}>
                  <div style={{ padding: '5px 8px' }}>
                    {isHome || isCreateProperty ? (
                      <NavLink
                        to={generatePath(RouteTypes.userSettings, {
                          directoryName: '',
                        })}
                        className={() =>
                          // { isActive }
                          cn(styles.routeLeftBar, {
                            [styles.activeLeftMenu]: isUserSettings,
                            // (!isHome || !isCreateProperty || !isUserSettings) && isActive,
                          })
                        }>
                        <Icon name="myAccount" className={styles.routeIconLeftMenu} />
                        <div className={styles.textWrapper} style={{ overflow: 'elipsis' }}>
                          My Account
                        </div>
                      </NavLink>
                    ) : (
                      <Dropdown
                        isSmall
                        placement="right"
                        trigger={['hover']}
                        triggerElement={
                          <NavLink
                            to={generatePath(RouteTypes.userSettings, {
                              directoryName: '',
                            })}
                            end
                            className={() =>
                              // { isActive }
                              cn(styles.routeLeftBar, {
                                [styles.activeLeftMenu]: isUserSettings,
                                // (!isHome || !isCreateProperty || !isUserSettings) && isActive,
                              })
                            }>
                            <Icon name="myAccount" className={styles.routeIconLeftMenu} />
                          </NavLink>
                        }>
                        <span className={styles.dropdownText}>My Account</span>
                      </Dropdown>
                    )}
                  </div>
                </Col>
                <Col key="statusCenter" span={24}>
                  <div style={{ padding: '5px 8px' }}>
                    {isHome || isCreateProperty ? (
                      <NavLink
                        onClick={() => handleOpenLink(LINKS.statusCenter)}
                        // @ts-ignore
                        to={undefined}
                        className={() =>
                          // { isActive }
                          cn(styles.routeLeftBar, {
                            // [styles.activeLeftMenu]: (!isHome || !isCreateProperty) && isActive,
                          })
                        }>
                        <Icon name="statusCenter" className={styles.routeIconLeftMenu} />
                        <div className={styles.textWrapper} style={{ overflow: 'elipsis' }}>
                          Status Center
                        </div>
                      </NavLink>
                    ) : (
                      <Dropdown
                        isSmall
                        placement="right"
                        trigger={['hover']}
                        triggerElement={
                          <NavLink
                            onClick={() => handleOpenLink(LINKS.statusCenter)}
                            // @ts-ignore
                            to={undefined}
                            end
                            className={() =>
                              // { isActive }
                              cn(styles.routeLeftBar, {
                                // [styles.activeLeftMenu]: (!isHome || !isCreateProperty) && isActive,
                              })
                            }>
                            <Icon name="statusCenter" className={styles.routeIconLeftMenu} />
                          </NavLink>
                        }>
                        <span className={styles.dropdownText}>Status Center</span>
                      </Dropdown>
                    )}
                  </div>
                </Col>
                <Col key="helpCenter" span={24}>
                  <div style={{ padding: '5px 8px' }}>
                    {isHome || isCreateProperty ? (
                      <NavLink
                        onClick={() => handleOpenLink(LINKS.helpCenter)}
                        // @ts-ignore
                        to={undefined}
                        className={() =>
                          // { isActive }
                          cn(styles.routeLeftBar, {
                            // [styles.activeLeftMenu]: (!isHome || !isCreateProperty) && isActive,
                          })
                        }>
                        <Icon name="helpCenter" className={styles.routeIconLeftMenu} />
                        <div className={styles.textWrapper} style={{ overflow: 'elipsis' }}>
                          Help Center
                        </div>
                      </NavLink>
                    ) : (
                      <Dropdown
                        isSmall
                        placement="right"
                        trigger={['hover']}
                        triggerElement={
                          <NavLink
                            onClick={() => handleOpenLink(LINKS.helpCenter)}
                            // @ts-ignore
                            to={undefined}
                            end
                            className={() =>
                              // { isActive }
                              cn(styles.routeLeftBar, {
                                // [styles.activeLeftMenu]: (!isHome || !isCreateProperty) && isActive,
                              })
                            }>
                            <Icon name="helpCenter" className={styles.routeIconLeftMenu} />
                          </NavLink>
                        }>
                        <span className={styles.dropdownText}>Help Center</span>
                      </Dropdown>
                    )}
                  </div>
                </Col>
                <Col key="logOut" span={24}>
                  <div style={{ padding: '5px 8px' }}>
                    {isHome || isCreateProperty ? (
                      //   <button
                      //   onClick={() => logout({ returnTo: window.location.origin })}
                      //   type="button"
                      //   className={styles.dropdownButton}>
                      //   <Icon name="exit" className={styles.dropdownIcon} />
                      //   Log Out
                      // </button>
                      <NavLink
                        onClick={() => logout({ returnTo: window.location.origin })}
                        // @ts-ignore
                        to={undefined}
                        className={() =>
                          // { isActive }
                          cn(styles.routeLeftBar, {
                            // [styles.activeLeftMenu]: (!isHome || !isCreateProperty) && isActive,
                          })
                        }>
                        <Icon name="exit" className={styles.routeIconLeftMenu} />
                        <div className={styles.textWrapper} style={{ overflow: 'elipsis' }}>
                          Log Out
                        </div>
                      </NavLink>
                    ) : (
                      <Dropdown
                        isSmall
                        placement="right"
                        trigger={['hover']}
                        triggerElement={
                          <NavLink
                            onClick={() => logout({ returnTo: window.location.origin })}
                            // @ts-ignore
                            to={undefined}
                            end
                            className={() =>
                              // { isActive }
                              cn(styles.routeLeftBar, {
                                // [styles.activeLeftMenu]: (!isHome || !isCreateProperty) && isActive,
                              })
                            }>
                            <Icon name="exit" className={styles.routeIconLeftMenu} />
                          </NavLink>
                        }>
                        <span className={styles.dropdownText}>Log Out</span>
                      </Dropdown>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Sider>

          {/* <MainLayoutUserDropdown billable={data?.data?.billable} /> */}
        </div>
        {!isCreateProperty && (
          <Sider
            trigger={null}
            width={isHome ? 0 : 176}
            collapsedWidth={isHome ? 0 : 48}
            collapsible
            collapsed={isMenuColapsed}
            theme="light"
            style={{
              position: 'fixed',
              left: 48,
              top: isAdminHeaderDisplayed ? '60px' : 0,
              bottom: 0,
              height: '100vh',
              zIndex: 2,
              backgroundColor: '#F2F8FC',
              overflow: 'auto',
            }}>
            {isUserSettings ? (
              <Row justify="start">
                <Col>
                  <div
                    style={{ display: 'flex', padding: '14px 0px 14px 8px', alignItems: 'center' }}>
                    <Icon name="myAccount" className={styles.routeIcon} />
                    {!isMenuColapsed && (
                      <span
                        style={{
                          display: 'block',
                          position: 'relative',
                          width: '100%',
                          fontSize: '18px',
                          lineHeight: '23px',
                          fontWeight: 600,
                          color: '#323539',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          cursor: 'default',
                          // bottom: '2px',
                        }}>
                        My Account
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
            ) : (
              <Row justify="start">
                <Col>
                  <div
                    style={{ display: 'flex', padding: '14px 0px 14px 8px', alignItems: 'center' }}>
                    <Icon name="property" className={styles.routeIcon} />
                    {!isMenuColapsed && (
                      <span
                        style={{
                          display: 'block',
                          position: 'relative',
                          width: '100%',
                          fontSize: '18px',
                          lineHeight: '23px',
                          fontWeight: 600,
                          color: '#323539',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          cursor: 'default',
                          paddingLeft: '8px',
                          // bottom: '2px',
                        }}>
                        {propertyName}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
            )}

            {/* <Row justify="end"> */}
            {/* <Col style={{ height: 33 }}>
                <Icon
                  name={!isMenuColapsed ? 'leftArrow' : 'rightArrow'}
                  className="ProjectHead__collapsed"
                  onClick={() => dispatch(toggleMenu())}
                  style={{ margin: '16px 15px 0', fontSize: 12, color: 'gray' }}
                />
              </Col> */}
            {/* {isUserSettings && (
                <Col
                  style={{
                    position: 'absolute',
                    right: isMenuColapsed ? '16px' : '13px',
                    top: '18px',
                  }}>
                  {isMenuColapsed ? (
                    <Icon
                      style={{ fontSize: 16 }}
                      name="menu-open2"
                      className="ProjectHead__collapsed"
                      onClick={() => dispatch(toggleMenu())}
                    />
                  ) : (
                    <Icon
                      style={{ fontSize: 16 }}
                      name="menu-open"
                      className="ProjectHead__collapsed"
                      onClick={() => dispatch(toggleMenu())}
                    />
                  )}
                </Col>
              )} */}
            {/* </Row> */}

            {isUserSettings ? (
              // <div style={{ width: '100%', marginTop: 0 }} />
              <></>
            ) : (
              <div style={{ width: '100%', marginTop: 2 }} />

              // ENVIRONMENTS DROPDOWN

              // <Row>
              //   <Col span={24}>
              //     <div className={cn(styles.project, { [styles.collapsed]: isMenuColapsed })}>
              //       <div
              //         style={{
              //           display: 'flex',
              //           flexDirection: 'row',
              //           justifyContent: 'flex-start',
              //           alignItems: 'center',
              //           flexWrap: 'nowrap',
              //           // gridGap: '12px',
              //           // gap: '12px',
              //           fontSize: '0.875rem',
              //           fontWeight: 600,
              //           color: '#445a84',
              //           width: '100%',
              //           whiteSpace: 'nowrap',
              //           overflow: 'hidden',
              //           textOverflow: 'ellipsis',
              //           height: '30px',
              //           position: 'relative',
              //           marginBottom: '11px',
              //           // right: '6px',
              //         }}>
              //         <Icon
              //           name="environments"
              //           style={{
              //             fontSize: '16px',
              //             color: '#656B74',
              //             padding: '6px 8px',
              //           }}
              //         />
              //         {!isMenuColapsed && (
              //           <span style={{ fontWeight: 600, marginLeft: 7, color: '#656B74' }}>
              //             Environments
              //           </span>
              //         )}
              //       </div>
              //       {!isHome && !isUserSettings && (
              //         <div style={{ margin: '3px 10px' }}>
              //           <MainLayoutProjectDropdown />
              //         </div>
              //       )}
              //       {/* {!isHome && isUserSettings && (
              //       <span className={styles.projectName}>User Settings</span>
              //     )} */}
              //     </div>
              //   </Col>
              // </Row>

              // ENVIRONMENTS DROPDOWN
            )}

            {!isHome && !isUserSettings && (
              <div className={cn(styles.nav, { [styles.collapsed]: isMenuColapsed })}>
                <Row>
                  {map(currentNavMenu, ({ name, to, end, icon, subrouts }) =>
                    !subrouts ? (
                      <Col key={to} span={24}>
                        <div style={{ padding: '5px 8px' }}>
                          {isMenuColapsed ? (
                            <Dropdown
                              isSmall
                              placement="right"
                              trigger={['hover']}
                              triggerElement={
                                <NavLink
                                  onClick={() => name}
                                  to={generatePath(to, { directoryName: directoryName || '' })}
                                  end={end}
                                  className={({ isActive }) =>
                                    cn(styles.routeNav, {
                                      [styles.active]: (!isHome || !isCreateProperty) && isActive,
                                    })
                                  }>
                                  <Icon
                                    name={icon}
                                    className={
                                      name === 'Access Control'
                                        ? styles.routeIconGold
                                        : styles.routeIcon
                                    }
                                  />
                                </NavLink>
                              }>
                              <span className={styles.dropdownText}>{name}</span>
                            </Dropdown>
                          ) : (
                            <NavLink
                              to={generatePath(to, { directoryName: directoryName || '' })}
                              end={end}
                              className={({ isActive }) =>
                                cn(styles.routeNav, {
                                  [styles.active]: (!isHome || !isCreateProperty) && isActive,
                                })
                              }>
                              <Icon
                                name={icon}
                                className={
                                  name === 'Access Control'
                                    ? styles.routeIconGold
                                    : styles.routeIcon
                                }
                              />
                              <span className={styles.navMenuText}>{name}</span>
                            </NavLink>
                          )}
                        </div>
                      </Col>
                    ) : (
                      <Col key={to} span={24}>
                        <MainLayoutSubroute
                          name={name}
                          data={subrouts}
                          directoryName={directoryName || ''}
                          icon={icon}
                        />
                      </Col>
                    )
                  )}
                </Row>
              </div>
            )}
            {!isHome && isUserSettings && (
              <div
                style={{ paddingTop: 2 }}
                className={cn(styles.nav, { [styles.collapsed]: isMenuColapsed })}>
                <Row>
                  {map(userSettingsNavMenu, ({ name, to, end, icon, subrouts }) =>
                    !subrouts ? (
                      <Col key={to} span={24}>
                        <div style={{ padding: '5px 8px' }}>
                          {isMenuColapsed ? (
                            <Dropdown
                              isSmall
                              placement="right"
                              trigger={['hover']}
                              triggerElement={
                                <button type="button" className={styles.trigger}>
                                  <NavLink
                                    to={generatePath(to, { directoryName: directoryName || '' })}
                                    end={end}
                                    className={({ isActive }) =>
                                      cn(styles.routeNav, {
                                        [styles.active]: isActive,
                                      })
                                    }>
                                    <Icon name={icon} className={styles.routeIcon} />
                                  </NavLink>
                                </button>
                              }>
                              <span className={styles.dropdownText}>{name}</span>
                            </Dropdown>
                          ) : (
                            <NavLink
                              to={generatePath(to, { directoryName: directoryName || '' })}
                              end={end}
                              className={({ isActive }) =>
                                cn(styles.routeNav, {
                                  [styles.active]: isActive,
                                })
                              }>
                              <Icon name={icon} className={styles.routeIcon} />
                              <span className={styles.navMenuText}>{name}</span>
                            </NavLink>
                          )}
                        </div>
                      </Col>
                    ) : (
                      <Col key={to} span={24} style={{ padding: '5px 8px' }}>
                        <MainLayoutSubroute
                          name={name}
                          data={subrouts}
                          // toUrl={to}
                          isEnd={end}
                          directoryName={directoryName || ''}
                          icon={icon}
                        />
                      </Col>
                    )
                  )}
                </Row>
              </div>
            )}
          </Sider>
        )}
        <Content
          className={cn(styles.content, {
            [styles.home]: isHome,
            [styles.collapsed]: !isHome && isMenuColapsed,
            [styles.loadingNewProperty]: isLoadingNewProperty,
          })}
          style={{
            top: isAdminHeaderDisplayed ? '60px' : '0px',
            position: 'relative',
            height: isAdminHeaderDisplayed ? 'calc(100vh - 60px)' : '100vh',
            marginLeft: isHome || isCreateProperty ? 150 : isMenuColapsed ? 96 : 224,
          }}>
          {isUserSettings && (
            <Row justify="start" style={{ padding: '16px' }}>
              {/* <Col style={{ height: 33 }}>
                <Icon
                  name={!isMenuColapsed ? 'leftArrow' : 'rightArrow'}
                  className="ProjectHead__collapsed"
                  onClick={() => dispatch(toggleMenu())}
                  style={{ margin: '16px 15px 0', fontSize: 12, color: 'gray' }}
                />
              </Col> */}

              <Col
              // style={{
              //   position: 'absolute',
              //   right: isMenuColapsed ? '16px' : '13px',
              //   top: '18px',
              // }}
              >
                <Row style={{ display: 'flex' }}>
                  {isMenuColapsed ? (
                    <Icon
                      style={{ fontSize: 16 }}
                      name="menu-open2"
                      className="ProjectHead__collapsed"
                      onClick={() => dispatch(toggleMenu())}
                    />
                  ) : (
                    <Icon
                      style={{ fontSize: 16 }}
                      name="menu-open"
                      className="ProjectHead__collapsed"
                      onClick={() => dispatch(toggleMenu())}
                    />
                  )}
                </Row>
              </Col>

              <Col style={{ paddingLeft: 16 }}>
                <span className={styles.pageName}>{pageNameSettings}</span>
              </Col>
            </Row>
          )}
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
