/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { Col, Collapse, Row } from 'antd';
import { ITlsVerify } from 'models';
import cn from 'classnames';
import { Input } from 'components/common/Input';
import Button from 'components/common/Button';
import Switch from 'components/common/Switch';
import { urlRegexValidation } from 'helpers/utils';
import Errormessage from './Errormessage';
import './HomeAddModalNew.scss';

const { Panel } = Collapse;

const OriginTlsSettings = ({
  tlsData,
  hostHeader,
  onChangeTls,
}: {
  onChangeTls: (data: ITlsVerify) => void;
  tlsData: ITlsVerify;
  hostHeader?: string;
}) => {
  const urlRegex = useMemo(() => urlRegexValidation, []);
  const [useSNI, setUseSNI] = useState(false);
  const [allowCerts, setAllowCerts] = useState(false);
  const [isValidSNI, setIsValidSNI] = useState(true);
  const [hostHeaderOrigin, setHostHeaderOrigin] = useState<string>();
  const [testhostname, setTesthostname] = useState('');
  const [sniValue, setSniValue] = useState('');

  useEffect(() => {
    if (hostHeader) {
      setHostHeaderOrigin(hostHeader);
    }
    setUseSNI(tlsData.use_sni);
    if (tlsData.sni_hint_and_strict_san_check) {
      setSniValue(tlsData.sni_hint_and_strict_san_check);
    }

    if (tlsData.allow_self_signed_certs) {
      setAllowCerts(tlsData.allow_self_signed_certs);
    }
  }, [
    hostHeader,
    tlsData.allow_self_signed_certs,
    tlsData.sni_hint_and_strict_san_check,
    tlsData.use_sni,
  ]);

  return (
    <Collapse collapsible="header" defaultActiveKey={['1']}>
      <Panel header="Origin TLS Settings" key="1">
        <Row align="middle" gutter={16}>
          <Col>
            <Switch
              checked={useSNI}
              onChange={(v) => {
                setUseSNI(!useSNI);
                const newOrigin = {
                  use_sni: v,
                  allow_self_signed_certs: allowCerts,
                  sni_hint_and_strict_san_check: sniValue || testhostname || undefined,
                };
                onChangeTls(newOrigin);
              }}
            />
          </Col>
          <Col>
            <span>Use SNI</span>
          </Col>
          <Col
            span={24}
            style={{
              fontSize: '0.75rem',
              fontWeight: 400,
              marginTop: 5,
            }}>
            Enable this to hint to your origin server which TLS certificate should be served. This
            is a common configuration. More information
          </Col>

          <Col span={24} style={{ marginTop: 10 }}>
            <Input
              placeholder={sniValue || hostHeaderOrigin || 'e.g "something.mysite.com"'}
              // placeholder="e.g. origin.example.com"
              isRequired={useSNI}
              label="Use the following SNI hint and enforce origin SAN/CN checking"
              value={sniValue || testhostname}
              onChange={(e: FormEvent<HTMLInputElement>) => {
                if (sniValue) {
                  setSniValue(e.currentTarget.value);
                } else {
                  setTesthostname(e.currentTarget.value);
                }
                const newOrigin = {
                  use_sni: useSNI,
                  allow_self_signed_certs: allowCerts,
                  sni_hint_and_strict_san_check: e.currentTarget.value,
                };
                onChangeTls(newOrigin);
              }}
              onBlur={() => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                sniValue || testhostname
                  ? setIsValidSNI(urlRegex.test(sniValue || testhostname))
                  : setIsValidSNI(true);
              }}
              className={cn('OriginNameInput', {
                _requiredField: useSNI
                  ? sniValue.length < 1
                  : false && (hostHeaderOrigin ? false : !(sniValue || testhostname)),
                _invalidURL: !isValidSNI,
              })}
            />
            {!isValidSNI && <Errormessage errormessage="The value you entered is not a valid." />}
          </Col>

          <Col style={{ marginTop: 10 }}>
            <Switch
              checked={allowCerts}
              onChange={(v) => {
                setAllowCerts(!allowCerts);
                const newOrigin = {
                  use_sni: useSNI,
                  allow_self_signed_certs: v,
                  sni_hint_and_strict_san_check: sniValue || testhostname || undefined,
                };
                onChangeTls(newOrigin);
              }}
            />
          </Col>
          <Col style={{ marginTop: 10 }}>
            <span>Allow Self Signed Certs</span>
          </Col>

          <Col style={{ marginTop: 10 }} span={24}>
            Pinned Cert(s)
          </Col>
          <Row style={{ marginTop: 10 }} gutter={[20, 20]} justify="space-between">
            <Col>
              <Button icon="plus" type="secondary" onClick={() => {}}>
                Add Pin
              </Button>
            </Col>
          </Row>
        </Row>
      </Panel>
    </Collapse>
  );
};

export default OriginTlsSettings;
