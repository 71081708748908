import React, { CSSProperties } from 'react';
import IconAntd from '@ant-design/icons';
import { map, keys, first } from 'lodash';
import { ReactComponent as ActionVertical } from '../../../assets/svg/ActionVertical.svg';
import { ReactComponent as Base } from '../../../assets/svg/Base.svg';
import { ReactComponent as British } from '../../../assets/svg/British.svg';
import { ReactComponent as Card } from '../../../assets/svg/Card.svg';
import { ReactComponent as Check } from '../../../assets/svg/Check.svg';
import { ReactComponent as CheckInCircle } from '../../../assets/svg/CheckInCircle.svg';
import { ReactComponent as Copy } from '../../../assets/svg/Copy.svg';
import { ReactComponent as CopyFilled } from '../../../assets/svg/CopyFilled.svg';
import { ReactComponent as Cross } from '../../../assets/svg/Cross.svg';
import { ReactComponent as CrossInCircle } from '../../../assets/svg/CrossInCircle.svg';
import { ReactComponent as Date } from '../../../assets/svg/Date.svg';
import { ReactComponent as Dropdown } from '../../../assets/svg/Dropdown.svg';
import { ReactComponent as Dropup } from '../../../assets/svg/Dropup.svg';
import { ReactComponent as Edit } from '../../../assets/svg/Edit.svg';
import { ReactComponent as Crown } from '../../../assets/svg/Crown.svg';
import { ReactComponent as Speed } from '../../../assets/svg/Speed.svg';
import { ReactComponent as VpnLock } from '../../../assets/svg/VpnLock.svg';
import { ReactComponent as Error } from '../../../assets/svg/Error.svg';
import { ReactComponent as Exit } from '../../../assets/svg/LogOutIcon.svg';
import { ReactComponent as Environments } from '../../../assets/svg/EnvironmentsIcon.svg';
import { ReactComponent as Empty } from '../../../assets/svg/Empty.svg';
import { ReactComponent as ExpandDown } from '../../../assets/svg/ExpandDown.svg';
import { ReactComponent as ExpandLeft } from '../../../assets/svg/ExpandLeft.svg';
import { ReactComponent as ExpandRight } from '../../../assets/svg/ExpandRight.svg';
import { ReactComponent as ExpandTopAndDown } from '../../../assets/svg/ExpandTopAndDown.svg';
import { ReactComponent as ExpandUp } from '../../../assets/svg/ExpandUp.svg';
import { ReactComponent as Export } from '../../../assets/svg/Export.svg';
import { ReactComponent as Eye } from '../../../assets/svg/Eye.svg';
import { ReactComponent as FullScreen } from '../../../assets/svg/FullScreen.svg';
import { ReactComponent as HelpFilled } from '../../../assets/svg/HelpFilled.svg';
import { ReactComponent as Hide } from '../../../assets/svg/Hide.svg';
import { ReactComponent as Horizontal } from '../../../assets/svg/Horizontal.svg';
import { ReactComponent as InfoInCircle } from '../../../assets/svg/InfoInCircle.svg';
import { ReactComponent as LineChart } from '../../../assets/svg/Line chart.svg';
import { ReactComponent as LineArrowDown } from '../../../assets/svg/LineArrowDown.svg';
import { ReactComponent as LineArrowLeft } from '../../../assets/svg/LineArrowLeft.svg';
import { ReactComponent as LineArrowRight } from '../../../assets/svg/LineArrowRight.svg';
import { ReactComponent as LineArrowUp } from '../../../assets/svg/LineArrowUP.svg';
import { ReactComponent as Link } from '../../../assets/svg/Link.svg';
import { ReactComponent as List } from '../../../assets/svg/List.svg';
import { ReactComponent as ListInBorder } from '../../../assets/svg/Listinborder.svg';
import { ReactComponent as Mail } from '../../../assets/svg/Mail.svg';
import { ReactComponent as Network } from '../../../assets/svg/Network.svg';
import { ReactComponent as Pin } from '../../../assets/svg/Pin.svg';
import { ReactComponent as Plus } from '../../../assets/svg/Plus.svg';
import { ReactComponent as QR } from '../../../assets/svg/QR.svg';
import { ReactComponent as Remove } from '../../../assets/svg/Remove.svg';
import { ReactComponent as Remove2 } from '../../../assets/svg/Remove2.svg';
import { ReactComponent as Search } from '../../../assets/svg/Search.svg';
import { ReactComponent as Settings } from '../../../assets/svg/SettingsIcon.svg';
import { ReactComponent as StatusCenter } from '../../../assets/svg/StatusCenterIcon.svg';
import { ReactComponent as HelpCenter } from '../../../assets/svg/HelpCenterIcon.svg';
import { ReactComponent as MyAccount } from '../../../assets/svg/MyAccountIcon.svg';
import { ReactComponent as StarEmpty } from '../../../assets/svg/StarEmpty.svg';
import { ReactComponent as Pencil } from '../../../assets/svg/Pencil.svg';
import { ReactComponent as Id } from '../../../assets/svg/Id.svg';
import { ReactComponent as Pci } from '../../../assets/svg/PCI.svg';
import { ReactComponent as Planet } from '../../../assets/svg/Planet.svg';
import { ReactComponent as StarFull } from '../../../assets/svg/StarFull.svg';
import { ReactComponent as Eur } from '../../../assets/svg/eur.svg';
import { ReactComponent as Usd } from '../../../assets/svg/usd.svg';
import { ReactComponent as Visibility } from '../../../assets/svg/Visibility.svg';
import { ReactComponent as Lock } from '../../../assets/svg/Lock.svg';
import { ReactComponent as PriorityHight } from '../../../assets/svg/PriorityHight.svg';
import { ReactComponent as Info } from '../../../assets/svg/Info.svg';
import { ReactComponent as Authy } from '../../../assets/svg/Authy.svg';
import { ReactComponent as Logo } from '../../../assets/svg/Logo.svg';
import { ReactComponent as Visa } from '../../../assets/svg/VisaLight.svg';
import { ReactComponent as MasterCard } from '../../../assets/svg/MasterCard.svg';
import { ReactComponent as ExternalLink } from '../../../assets/svg/ExternalLink.svg';
import { ReactComponent as Home } from '../../../assets/svg/Home.svg';
import { ReactComponent as Forward } from '../../../assets/svg/Forward.svg';
import { ReactComponent as PlayArrow } from '../../../assets/svg/PlayArrow.svg';
import { ReactComponent as Slow } from '../../../assets/svg/Slow.svg';
import { ReactComponent as ZoomOut } from '../../../assets/svg/ZoomOut.svg';
import { ReactComponent as AutoRenew } from '../../../assets/svg/AutoRenew.svg';
import { ReactComponent as Code } from '../../../assets/svg/Code.svg';
import { ReactComponent as Techonlogy } from '../../../assets/svg/Techonlogy.svg';
import { ReactComponent as GPS } from '../../../assets/svg/GPS.svg';
import { ReactComponent as Nginx } from '../../../assets/svg/Nginx.svg';
import { ReactComponent as Datadog } from '../../../assets/svg/Datadog.svg';
import { ReactComponent as Subtract } from '../../../assets/svg/Subtract.svg';
import { ReactComponent as Receipt } from '../../../assets/svg/Receipt.svg';
import { ReactComponent as ClockOutline } from '../../../assets/svg/ClockOutline.svg';
import { ReactComponent as Pause } from '../../../assets/svg/Pause.svg';
import { ReactComponent as Resize } from '../../../assets/svg/Resize.svg';
import { ReactComponent as API } from '../../../assets/svg/API.svg';
import { ReactComponent as CNAME } from '../../../assets/svg/CNAMEs.svg';
import { ReactComponent as LinkConnect } from '../../../assets/svg/LinkConnect.svg';
import { ReactComponent as Notifications } from '../../../assets/svg/Notifications.svg';
import { ReactComponent as Shield } from '../../../assets/svg/SecurityIcon.svg';
import { ReactComponent as Collaboration } from '../../../assets/svg/CollabIcon.svg';
import { ReactComponent as Hostnames } from '../../../assets/svg/Hostnames.svg';
import { ReactComponent as Ssl } from '../../../assets/svg/TlsCertIcon.svg';
import { ReactComponent as Key } from '../../../assets/svg/AccessControlIcon.svg';
import { ReactComponent as Service } from '../../../assets/svg/Service.svg';
import { ReactComponent as Security } from '../../../assets/svg/SecurityProfiles.svg';
import { ReactComponent as Cache } from '../../../assets/svg/Cache.svg';
import { ReactComponent as Origins } from '../../../assets/svg/OriginsIcon.svg';
import { ReactComponent as Deployment } from '../../../assets/svg/DeploymentsIcon.svg';
import { ReactComponent as Rules } from '../../../assets/svg/RulesIcon.svg';
import { ReactComponent as ArrowR } from '../../../assets/svg/ArrowR.svg';
import { ReactComponent as Diff } from '../../../assets/svg/Diff.svg';
import { ReactComponent as Toolbox } from '../../../assets/svg/Toolbox.svg';
import { ReactComponent as Http } from '../../../assets/svg/HTTP.svg';
import { ReactComponent as Monitor } from '../../../assets/svg/Monitor.svg';
import { ReactComponent as WAF } from '../../../assets/svg/WAF.svg';
import { ReactComponent as WAFR } from '../../../assets/svg/WAFR.svg';
import { ReactComponent as WAFRB } from '../../../assets/svg/WAFRB.svg';
import { ReactComponent as AccountInCircle } from '../../../assets/svg/AccountInCircle.svg';
import { ReactComponent as ClockOutline2 } from '../../../assets/svg/ClockOutline2.svg';
import { ReactComponent as Status } from '../../../assets/svg/Status.svg';
import { ReactComponent as Version } from '../../../assets/svg/Version.svg';
import { ReactComponent as Dynamic } from '../../../assets/svg/Dynamic.svg';
import { ReactComponent as PrimaryDomain } from '../../../assets/svg/PrimaryDomain.svg';
import { ReactComponent as Rule } from '../../../assets/svg/Rule.svg';
import { ReactComponent as Static } from '../../../assets/svg/Static.svg';
import { ReactComponent as Stripe } from '../../../assets/svg/Stripe.svg';
import { ReactComponent as Redirect } from '../../../assets/svg/Redirect.svg';
import { ReactComponent as LogoSmall } from '../../../assets/svg/LogoSmall.svg';
import { ReactComponent as MenuOpen2 } from '../../../assets/svg/MenuOpen2.svg';
import { ReactComponent as MenuOpen } from '../../../assets/svg/MenuOpen.svg';
import { ReactComponent as LogoFull } from '../../../assets/svg/LogoFull.svg';
import { ReactComponent as ProfileOutline } from '../../../assets/svg/ProfileOutline.svg';
import { ReactComponent as Collapsed } from '../../../assets/svg/Collapsed.svg';
import { ReactComponent as Dashboard } from '../../../assets/svg/Dashboard.svg';
import { ReactComponent as Overview } from '../../../assets/svg/OverviewIcon.svg';
import { ReactComponent as Logs } from '../../../assets/svg/Logs.svg';
import { ReactComponent as LogsIcon } from '../../../assets/svg/LogsIcon.svg';
import { ReactComponent as Routing } from '../../../assets/svg/Routing.svg';
import { ReactComponent as BillingReports } from '../../../assets/svg/BillingReports.svg';
import { ReactComponent as Calendar } from '../../../assets/svg/Calendar.svg';
import { ReactComponent as PieChart } from '../../../assets/svg/PieChart.svg';
import { ReactComponent as Property } from '../../../assets/svg/Property.svg';
import { ReactComponent as LeftArrow } from '../../../assets/svg/LeftArrow.svg';
import { ReactComponent as RightArrow } from '../../../assets/svg/RightArrow.svg';
import { ReactComponent as Language } from '../../../assets/svg/Language.svg';
import { ReactComponent as setBilling } from '../../../assets/svg/setBilling.svg';
import { ReactComponent as setDetails } from '../../../assets/svg/setDetails.svg';
import { ReactComponent as setInvoice } from '../../../assets/svg/setInv.svg';
import { ReactComponent as setReport } from '../../../assets/svg/setReport.svg';

export const iconTestId = 'icon-test';

export type IconType =
  | 'action-vertical'
  | 'base'
  | 'british'
  | 'card'
  | 'check'
  | 'check-in-circle'
  | 'copy'
  | 'copy-filled'
  | 'cross'
  | 'cross-in-circle'
  | 'date'
  | 'dropdown'
  | 'dropup'
  | 'edit'
  | 'crown'
  | 'speed'
  | 'vpnLock'
  | 'error'
  | 'exit'
  | 'environments'
  | 'empty'
  | 'expand-down'
  | 'expand-left'
  | 'expand-right'
  | 'expand-up'
  | 'expand-top-and-down'
  | 'export'
  | 'eye'
  | 'full-screen'
  | 'help-filled'
  | 'hide'
  | 'horizontal'
  | 'info-in-circle'
  | 'linechart'
  | 'line-arrow-up'
  | 'line-arrow-right'
  | 'line-arrow-down'
  | 'line-arrow-left'
  | 'link'
  | 'list'
  | 'list-in-border'
  | 'mail'
  | 'network'
  | 'pin'
  | 'plus'
  | 'qr'
  | 'remove'
  | 'remove2'
  | 'search'
  | 'settings'
  | 'statusCenter'
  | 'helpCenter'
  | 'myAccount'
  | 'star-empty'
  | 'visibility'
  | 'lock'
  | 'priority-hight'
  | 'info'
  | 'logo'
  | 'authy'
  | 'visa'
  | 'master-card'
  | 'external-link'
  | 'forward'
  | 'play-arrow'
  | 'slow'
  | 'home'
  | 'zoom-out'
  | 'auto-renew'
  | 'techonlogy'
  | 'code'
  | 'gps'
  | 'nginx'
  | 'datadog'
  | 'subtract'
  | 'receipt'
  | 'clock-outline'
  | 'pause'
  | 'api'
  | 'cname'
  | 'link-connect'
  | 'notifications'
  | 'shield'
  | 'collaboration'
  | 'hostnames'
  | 'ssl'
  | 'key'
  | 'service'
  | 'security'
  | 'cache'
  | 'origins'
  | 'deployment'
  | 'rules'
  | 'arrowR'
  | 'diff'
  | 'toolbox'
  | 'http'
  | 'monitor'
  | 'waf'
  | 'wafr'
  | 'wafrb'
  | 'clock-outline-2'
  | 'status'
  | 'version'
  | 'acount-in-circle'
  | 'dynamic'
  | 'primary-domain'
  | 'rule'
  | 'static'
  | 'resize'
  | 'stripe'
  | 'redirect'
  | 'logo-small'
  | 'menu-open2'
  | 'menu-open'
  | 'logo-full'
  | 'profile-outline'
  | 'collapsed'
  | 'dashboard'
  | 'overview'
  | 'logs'
  | 'logsIcon'
  | 'routing'
  | 'star-full'
  | 'eur'
  | 'usd'
  | 'billing-reports'
  | 'calendar'
  | 'pie'
  | 'property'
  | 'leftArrow'
  | 'rightArrow'
  | 'language'
  | 'set-billing'
  | 'set-details'
  | 'set-invoice'
  | 'set-reports'
  | 'pencil'
  | 'id'
  | 'planet'
  | 'pci';

export interface Props {
  className?: string;
  name: IconType | undefined;
  style?: CSSProperties;
  spin?: boolean;
  onClick?: () => void;
}

const getIconName = (name?: IconType) => `icon-${name}`;
export const getIconType = (name: string): IconType => {
  return String(name).replace('icon-', '') as IconType;
};

export const icons = {
  [getIconName('action-vertical')]: ActionVertical,
  [getIconName('base')]: Base,
  [getIconName('british')]: British,
  [getIconName('card')]: Card,
  [getIconName('check')]: Check,
  [getIconName('check-in-circle')]: CheckInCircle,
  [getIconName('copy')]: Copy,
  [getIconName('copy-filled')]: CopyFilled,
  [getIconName('cross')]: Cross,
  [getIconName('cross-in-circle')]: CrossInCircle,
  [getIconName('date')]: Date,
  [getIconName('dropdown')]: Dropdown,
  [getIconName('dropup')]: Dropup,
  [getIconName('edit')]: Edit,
  [getIconName('crown')]: Crown,
  [getIconName('speed')]: Speed,
  [getIconName('vpnLock')]: VpnLock,
  [getIconName('error')]: Error,
  [getIconName('exit')]: Exit,
  [getIconName('environments')]: Environments,
  [getIconName('empty')]: Empty,
  [getIconName('expand-down')]: ExpandDown,
  [getIconName('expand-left')]: ExpandLeft,
  [getIconName('expand-right')]: ExpandRight,
  [getIconName('expand-up')]: ExpandUp,
  [getIconName('expand-top-and-down')]: ExpandTopAndDown,
  [getIconName('export')]: Export,
  [getIconName('eye')]: Eye,
  [getIconName('full-screen')]: FullScreen,
  [getIconName('help-filled')]: HelpFilled,
  [getIconName('hide')]: Hide,
  [getIconName('horizontal')]: Horizontal,
  [getIconName('info-in-circle')]: InfoInCircle,
  [getIconName('linechart')]: LineChart,
  [getIconName('line-arrow-up')]: LineArrowUp,
  [getIconName('line-arrow-right')]: LineArrowRight,
  [getIconName('line-arrow-down')]: LineArrowDown,
  [getIconName('line-arrow-left')]: LineArrowLeft,
  [getIconName('link')]: Link,
  [getIconName('list')]: List,
  [getIconName('list-in-border')]: ListInBorder,
  [getIconName('mail')]: Mail,
  [getIconName('network')]: Network,
  [getIconName('pin')]: Pin,
  [getIconName('plus')]: Plus,
  [getIconName('qr')]: QR,
  [getIconName('remove')]: Remove,
  [getIconName('remove2')]: Remove2,
  [getIconName('search')]: Search,
  [getIconName('settings')]: Settings,
  [getIconName('statusCenter')]: StatusCenter,
  [getIconName('helpCenter')]: HelpCenter,
  [getIconName('myAccount')]: MyAccount,
  [getIconName('star-empty')]: StarEmpty,
  [getIconName('pencil')]: Pencil,
  [getIconName('pci')]: Pci,
  [getIconName('planet')]: Planet,
  [getIconName('id')]: Id,
  [getIconName('visibility')]: Visibility,
  [getIconName('lock')]: Lock,
  [getIconName('priority-hight')]: PriorityHight,
  [getIconName('info')]: Info,
  [getIconName('authy')]: Authy,
  [getIconName('logo')]: Logo,
  [getIconName('visa')]: Visa,
  [getIconName('master-card')]: MasterCard,
  [getIconName('external-link')]: ExternalLink,
  [getIconName('home')]: Home,
  [getIconName('forward')]: Forward,
  [getIconName('play-arrow')]: PlayArrow,
  [getIconName('slow')]: Slow,
  [getIconName('zoom-out')]: ZoomOut,
  [getIconName('auto-renew')]: AutoRenew,
  [getIconName('code')]: Code,
  [getIconName('techonlogy')]: Techonlogy,
  [getIconName('gps')]: GPS,
  [getIconName('nginx')]: Nginx,
  [getIconName('datadog')]: Datadog,
  [getIconName('subtract')]: Subtract,
  [getIconName('receipt')]: Receipt,
  [getIconName('clock-outline')]: ClockOutline,
  [getIconName('pause')]: Pause,
  [getIconName('resize')]: Resize,
  [getIconName('api')]: API,
  [getIconName('cname')]: CNAME,
  [getIconName('link-connect')]: LinkConnect,
  [getIconName('notifications')]: Notifications,
  [getIconName('shield')]: Shield,
  [getIconName('collaboration')]: Collaboration,
  [getIconName('hostnames')]: Hostnames,
  [getIconName('ssl')]: Ssl,
  [getIconName('key')]: Key,
  [getIconName('service')]: Service,
  [getIconName('security')]: Security,
  [getIconName('cache')]: Cache,
  [getIconName('origins')]: Origins,
  [getIconName('deployment')]: Deployment,
  [getIconName('rules')]: Rules,
  [getIconName('arrowR')]: ArrowR,
  [getIconName('diff')]: Diff,
  [getIconName('toolbox')]: Toolbox,
  [getIconName('http')]: Http,
  [getIconName('monitor')]: Monitor,
  [getIconName('waf')]: WAF,
  [getIconName('wafr')]: WAFR,
  [getIconName('wafrb')]: WAFRB,
  [getIconName('clock-outline-2')]: ClockOutline2,
  [getIconName('status')]: Status,
  [getIconName('version')]: Version,
  [getIconName('acount-in-circle')]: AccountInCircle,
  [getIconName('dynamic')]: Dynamic,
  [getIconName('primary-domain')]: PrimaryDomain,
  [getIconName('rule')]: Rule,
  [getIconName('static')]: Static,
  [getIconName('stripe')]: Stripe,
  [getIconName('redirect')]: Redirect,
  [getIconName('logo-small')]: LogoSmall,
  [getIconName('menu-open')]: MenuOpen,
  [getIconName('menu-open2')]: MenuOpen2,
  [getIconName('logo-full')]: LogoFull,
  [getIconName('profile-outline')]: ProfileOutline,
  [getIconName('collapsed')]: Collapsed,
  [getIconName('dashboard')]: Dashboard,
  [getIconName('overview')]: Overview,
  [getIconName('logs')]: Logs,
  [getIconName('logsIcon')]: LogsIcon,
  [getIconName('routing')]: Routing,
  [getIconName('star-full')]: StarFull,
  [getIconName('eur')]: Eur,
  [getIconName('usd')]: Usd,
  [getIconName('billing-reports')]: BillingReports,
  [getIconName('calendar')]: Calendar,
  [getIconName('pie')]: PieChart,
  [getIconName('property')]: Property,
  [getIconName('leftArrow')]: LeftArrow,
  [getIconName('rightArrow')]: RightArrow,
  [getIconName('language')]: Language,
  [getIconName('set-billing')]: setBilling,
  [getIconName('set-details')]: setDetails,
  [getIconName('set-invoice')]: setInvoice,
  [getIconName('set-reports')]: setReport,
};

export const iconNames = map(keys(icons), getIconType);
export const firstIconName = getIconType(String(first(keys(icons))));

export const Icon: React.FC<Props> = (props) => {
  const { className, name, style, spin, onClick } = props;

  const IconComponent = icons[getIconName(name)] || null;

  return (
    <IconAntd
      {...props}
      style={style}
      component={IconComponent}
      className={className}
      spin={spin}
      data-testid={iconTestId}
      onClick={onClick}
    />
  );
};

export default Icon;
