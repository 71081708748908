/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-nested-ternary */
import React, { FC, FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row, Skeleton, Collapse, Tooltip } from 'antd';
import { ClockCircleOutlined, WarningOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Button from 'components/common/Button';
import { Notification } from 'components/common/Notification';
import { Icon } from 'components/common/Icon';
import cn from 'classnames';
import Switch from 'components/common/Switch';
import { Input } from 'components/common/Input';
import { useParams, Link, generatePath, useNavigate, useLocation } from 'react-router-dom';
import { Drawer } from 'components/common/Drawer';
import { Loader } from 'components/common/Loader';
import { useAppDispatch, useAppSelector } from 'store';
import { getSkeletonsSize, expiresTime, updateSkeletonsSize } from 'helpers/utils';
import { updateTLSDetails } from 'store/slices/property';
import { propertyAPI } from 'services';
import CopyToClipboard from 'react-copy-to-clipboard';
import RouteTypes from 'router/types';
import { size } from 'lodash';
import { IPropertyCertificatesData, IPropertySSLData } from 'models';
import './ProjectOriginTLSView.scss';

const { Panel } = Collapse;

export const ProjectOriginTLSView: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const refAcmeHostname = useRef<HTMLButtonElement>(null);
  const refAcmeConst = useRef<HTMLButtonElement>(null);
  const { directoryName } = useParams<{ directoryName?: string }>();
  const { token } = useAppSelector(({ auth }) => auth);

  const [tlsAutomatically, setTlsAutomatically] = useState(true);
  const [visibleDrawer, setVisibleDrawe] = useState(false);
  const [hostNameConfigurationName, setHostNameConfigurationName] = useState<string>('');
  const [skeletons] = useState(getSkeletonsSize('crtf', String(directoryName)));

  const [primaryCert, setPrimaryCert] = useState('');
  const [intermediateCert, setIntermediateCert] = useState('');
  const [privateKeyCert, setPrivateKeyCert] = useState('');
  const [refetchedName, setRefetchedName] = useState('');
  const [
    certifPolling,
    // setCertifPolling
  ] = useState(false);

  const showDrawer = useCallback((name: string) => {
    setVisibleDrawe(true);
    setHostNameConfigurationName(name);
  }, []);

  const onClose = useCallback(() => {
    setVisibleDrawe(false);
  }, []);

  const {
    data: dataTLS,
    refetch: refetchDataTLS,
    isLoading: isSslCertLoading,
    isFetching: isSslCertificateFetching,
  } = propertyAPI.useFetchDomainsQuery(String(directoryName), {
    pollingInterval: certifPolling ? 5000 : 0,
    skip: !token,
  });

  const {
    data: tlsCertificates,
    refetch: refetchTlsCertificates,
    isLoading: isTlsCertificatesLoading,
    // isFetching: isTlsCerificatesFetching,
  } = propertyAPI.useFetchTLSCerificatesQuery(String(directoryName), {
    pollingInterval: certifPolling ? 5000 : 0,
    skip: !token,
  });

  const [
    genereteTLS,
    {
      isLoading: isGenereteLoading,
      // , isSuccess: isGenereteSuccess
    },
  ] = propertyAPI.useGenereteTLSMutation();
  const [
    uploadTLS,
    {
      isLoading: isUploadLoading,
      // , isSuccess: isUploadSuccess
    },
  ] = propertyAPI.useUploadTLSMutation();

  useEffect(() => {
    dispatch(updateTLSDetails(dataTLS?.data as IPropertySSLData[]));

    if (size([...(dataTLS?.data || [])])) {
      updateSkeletonsSize('crtf', String(directoryName), size([...(dataTLS?.data ?? [])]));
    }
  }, [directoryName, dispatch, dataTLS?.data]);

  const collapseHeaderLoading = useCallback(
    () => (
      <div className="tlsCollapseWrapper">
        <div>Present Certificate:</div>
        <div className="tlsCollapseLoader">
          <Loader />
        </div>
      </div>
    ),
    []
  );

  const collapseHeader = useCallback(
    (status: string, expiration: string) => (
      <div className="tlsCollapseWrapper">
        <div>Present Certificate:</div>
        {status === 'activated' ? (
          <>
            <div className="tlsLabel" style={{ margin: '0 10px' }}>
              <div className="tlsLabelInner">
                <Tooltip placement="bottom" title="Activated">
                  <span className="tlsLabelStatus" />
                </Tooltip>
                <span>Active</span>
              </div>
            </div>
            <div>
              <span>{expiresTime(expiration)}</span>
            </div>
          </>
        ) : (
          <div className="tlsLabel" style={{ margin: '0 10px', borderColor: 'grey' }}>
            <div className="tlsLabelInner" style={{ color: 'mediumvioletred' }}>
              <Tooltip placement="bottom" title="Missing">
                <span
                  className="tlsLabelStatus"
                  style={{
                    backgroundColor: 'mediumvioletred',
                  }}
                />
              </Tooltip>
              <span>Not yet generated</span>
            </div>
          </div>
        )}
      </div>
    ),
    []
  );

  const isActiveGenereteSslBtn = useMemo(() => {
    return dataTLS?.data && dataTLS?.data?.some((item) => item.validTlsCname && item.validTlsCaa);
  }, [dataTLS?.data]);

  // test it
  // test it
  // test it

  // const isCertDeploying = useMemo(() => {
  //   return (
  //     tlsCertificates?.data?.properties &&
  //     tlsCertificates?.data?.properties.some((item) => item.status === 'deploying') // status ???
  //   );
  // }, [tlsCertificates?.data?.properties]);

  // test it
  // test it
  // test it

  const certificatesTable = useMemo(() => {
    const isVisibleGenerateBtn = [...(dataTLS?.data || [])].every((objB: IPropertySSLData) =>
      [...(tlsCertificates?.data?.properties || [])].some(
        (objA: IPropertyCertificatesData) => objA.common_name === objB.name
      )
    );

    const isCertActive = (tlsCerts: IPropertyCertificatesData[], tlsName: string) => {
      if (!Array.isArray(tlsCerts)) {
        return false;
      }
      return tlsCerts.some((property) => property.common_name === tlsName);
    };

    const refetchData = (name: string) => {
      setRefetchedName(name);
      refetchDataTLS()
        .unwrap()
        .then(() => setRefetchedName(''))
        .catch((err) =>
          Notification({
            type: 'cross',
            title: 'Error',
            message: err.data.message,
          })
        );
      refetchTlsCertificates()
        .unwrap()
        .catch((err) =>
          Notification({
            type: 'cross',
            title: 'Error',
            message: err.data.message,
          })
        );
    };

    const renderSkeletonRow = (index: number | string) => (
      <Row key={index} gutter={32} align="middle" className="ProjectOriginTLSView__table-head">
        <Col span={24} className="ProjectOriginTLSView__head-col _ta-left">
          <Skeleton.Button active size="small" block style={{ margin: '5px 0px' }} />
        </Col>
      </Row>
    );

    const renderCertRow = (tlsCert: IPropertySSLData) => (
      <Row key={tlsCert.name} gutter={32} align="middle" className="tableRow">
        <Col span={7} className="ProjectOriginTLSView__head-col _ta-left">
          <div
            style={{
              display: 'block',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}>
            {tlsCert.name}
          </div>
        </Col>
        <Col span={6} className="ProjectOriginTLSView__head-col _ta_left">
          <span style={{ color: tlsCert.validTlsCaa ? 'green' : 'red', fontSize: 20 }}>
            <Icon name={tlsCert.validTlsCaa ? 'check-in-circle' : 'cross-in-circle'} />
          </span>
        </Col>
        <Col span={8} className="ProjectOriginTLSView__head-col _ta_left">
          {tlsCert.validTlsCname ? (
            <span style={{ color: 'green', fontSize: 22 }}>
              <Icon
                name="check-in-circle"
                style={{ cursor: 'pointer', position: 'relative', top: 1 }}
                // onClick={() => showDrawer(tlsCert.name)}
              />
            </span>
          ) : (
            <Tooltip title="Not configured properly. Click for details.">
              <span style={{ color: 'red', fontSize: 22 }}>
                <Icon
                  name="cross-in-circle"
                  style={{ cursor: 'pointer', position: 'relative', top: 1 }}
                  onClick={() => showDrawer(tlsCert.name)}
                />
              </span>
            </Tooltip>
          )}

          {!isCertActive(
            tlsCertificates?.data?.properties as IPropertyCertificatesData[],
            tlsCert.name
          ) &&
            !isTlsCertificatesLoading && (
              <span>
                <Icon
                  className={cn('ProjectOriginTLSView__arrowIcon', {
                    _arrowIconDisabled: isSslCertificateFetching,
                  })}
                  name="arrowR"
                  onClick={() => refetchData(tlsCert.name)}
                />
              </span>
            )}
        </Col>
        <Col span={3} className="ProjectOriginTLSView__head-col _ta_left">
          <div
            style={{
              backgroundColor: isCertActive(
                tlsCertificates?.data?.properties as IPropertyCertificatesData[],
                tlsCert.name
              )
                ? 'green'
                : 'grey',
              borderRadius: '50%',
              width: 18,
              height: 18,
            }}
          />
        </Col>
      </Row>
    );

    const renderCertRows = () =>
      (dataTLS?.data as IPropertySSLData[]).map((tlsCert: IPropertySSLData) =>
        tlsCert.name === refetchedName && isSslCertificateFetching
          ? renderSkeletonRow(tlsCert.name)
          : renderCertRow(tlsCert)
      );

    const renderContent = () => {
      if (isSslCertLoading) {
        return Array.from({ length: skeletons }).map((_, indx) => renderSkeletonRow(indx));
      }

      if (dataTLS?.data && dataTLS?.data?.length > 0) {
        return renderCertRows();
      }

      return (
        <Row key="0" gutter={32} align="middle" className="ProjectOriginTLSView__table-head">
          <Col span={24} className="ProjectOriginTLSView__head-col">
            In order to configure a TLS certificate you must first add a hostname
            <span
              style={{
                padding: '0px 3px',
                color: 'dodgerblue',
                position: 'relative',
                fontSize: '18px',
                top: '1px',
              }}>
              <Icon
                name="external-link"
                onClick={() => {
                  navigate(
                    generatePath(RouteTypes.settingsHostnames, {
                      directoryName: directoryName || '',
                    })
                  );
                }}
              />
            </span>
          </Col>
        </Row>
      );
    };

    return (
      <div>
        <div>
          <Row gutter={32} className="ProjectOriginTLSView__table-head">
            <Col span={7} className="ProjectOriginTLSView__head-col _ta-left">
              <span className="ProjectOriginTLSView__head-label">Hostname</span>
            </Col>
            <Col span={6} className="ProjectOriginTLSView__head-col _ta-left">
              <span className="ProjectOriginTLSView__head-label">CAA Record</span>
            </Col>
            <Col span={8} className="ProjectOriginTLSView__head-col _ta-left">
              <span className="ProjectOriginTLSView__head-label">ACME Challenge Record</span>
            </Col>
            <Col span={3} className="ProjectOriginTLSView__head-col _ta-left">
              <span className="ProjectOriginTLSView__head-label">TLS</span>
            </Col>
          </Row>
        </div>

        <div className="ProjectOriginTLSView__table-wrapper">{renderContent()}</div>

        {!isTlsCertificatesLoading && !isVisibleGenerateBtn && (
          <Row>
            <Button
              loading={isGenereteLoading}
              // loading={isGenereteLoading || isCertDeploying} // test it   // test it  // test it
              disabled={!isActiveGenereteSslBtn}
              type="primary"
              onClick={() => {
                document.cookie = `tls=${location.pathname}?loading`;
                genereteTLS(String(directoryName))
                  .unwrap()
                  .then((resp) => {
                    Notification({ title: resp.message, type: 'check' });
                    document.cookie = `tls=${location.pathname}?loading`;
                  })
                  .catch((err) =>
                    Notification({ type: 'cross', title: 'Error', message: err.data.message })
                  );
              }}>
              Generate TLS
            </Button>
          </Row>
        )}
      </div>
    );
  }, [
    dataTLS?.data,
    isTlsCertificatesLoading,
    isGenereteLoading,
    isActiveGenereteSslBtn,
    tlsCertificates?.data?.properties,
    refetchDataTLS,
    refetchTlsCertificates,
    isSslCertificateFetching,
    showDrawer,
    refetchedName,
    isSslCertLoading,
    skeletons,
    navigate,
    directoryName,
    genereteTLS,
    location.pathname,
  ]);

  const customCertificate = useMemo(() => {
    return (
      <>
        <Row>
          <Col span={24}>
            <p>
              In order to configure TLS for your domains, you need to generate a single certificate
              for all custom domains. Provide the certificate, intermediate certificates, and
              private key using the form below.
            </p>
          </Col>
          <Col span={24} style={{ width: '100%' }}>
            <Input
              className="inputCertif"
              isRequired
              onChange={(e: FormEvent<HTMLInputElement>) => {
                setPrimaryCert(e.currentTarget.value);
              }}
              label="Primary Certificate"
              textarea
              height="110px"
              placeholder={`Paste certificate here, e.g:

-----BEGIN CERTIFICATE-----
ABCDEFghijklMnOPq.........
-----END CERTIFICATE-----`}
            />
          </Col>
          <Col span={24} style={{ marginTop: '10px' }}>
            <Input
              className="inputCertif"
              isRequired
              onChange={(e: FormEvent<HTMLInputElement>) => {
                setIntermediateCert(e.currentTarget.value);
              }}
              textarea
              height="110px"
              label="Intermediate Certificate"
              placeholder={`Paste certificate(s), e.g:

-----BEGIN CERTIFICATE-----
ABCDEFghijklMnOPq.........
-----END CERTIFICATE-----

-----BEGIN CERTIFICATE-----
ABCDEFghijklMnOPq.........
-----END CERTIFICATE-----`}
            />
          </Col>
          <Col span={24} style={{ marginTop: '10px' }}>
            <Input
              className="inputCertif"
              isRequired
              onChange={(e: FormEvent<HTMLInputElement>) => {
                setPrivateKeyCert(e.currentTarget.value);
              }}
              textarea
              height="110px"
              label="Private Key"
              placeholder={`Paste private key here, e.g:

-----BEGIN PRIVATE KEY-----
ABCDEFghijklMnOPq.........
-----END PRIVATE KEY-----`}
            />
          </Col>
          <Col span={24} style={{ marginTop: '10px' }}>
            <p>Your private key will not be shown again once saved.</p>
          </Col>
          <Col span={24}>
            <Button
              loading={isUploadLoading}
              onClick={() => {
                uploadTLS({
                  propertyId: String(directoryName),
                  primary_cert: primaryCert,
                  intermediate_cert: intermediateCert,
                  private_key: privateKeyCert,
                })
                  .unwrap()
                  .then((resp) => {
                    // console.log('uploadTLSuploadTLS_resp', resp);
                    Notification({ type: 'check', title: resp.message });
                    setPrimaryCert('');
                    setIntermediateCert('');
                    setPrivateKeyCert('');
                  })
                  .catch((err) =>
                    Notification({ type: 'cross', title: 'Error', message: err.data.message })
                  );
              }}
              disabled={!primaryCert || !intermediateCert || !privateKeyCert}
              type="primary">
              Save Changes
            </Button>
          </Col>
        </Row>
      </>
    );
  }, [directoryName, intermediateCert, isUploadLoading, primaryCert, privateKeyCert, uploadTLS]);

  const rederDrawerFooter = useCallback(
    () => (
      <Row justify="end">
        <Button type="secondary" onClick={() => onClose()}>
          Close
        </Button>
      </Row>
    ),
    [onClose]
  );

  const renderConfiguration = useCallback(
    (name: string) => {
      return (
        <Drawer
          placement="right"
          onClose={onClose}
          open={visibleDrawer}
          mask={false}
          width={600}
          style={{ top: 0, bottom: 0, height: '100vh' }}
          contentWrapperStyle={{ boxShadow: '-5px 0px 30px rgba(25, 42, 62, 0.08' }}
          headerStyle={{ padding: '14px 24px', borderBottom: '1px solid #EBEFF2' }}
          closeIcon={<Icon name="cross" className="ProjectOriginTraffic__drawer-icon" />}
          headerTitle={
            <h4 className="ProjectOriginTraffic__drawer-title">
              DNS Configuration Problems Detected
            </h4>
          }
          bodyStyle={{ padding: 0 }}
          footer={rederDrawerFooter()}>
          <Row style={{ padding: 20 }} gutter={[20, 20]}>
            <Col span={24}>
              <span>
                The following problems must be fixed before a TLS certificate can be generated for
              </span>
              <span style={{ fontWeight: 600 }}>{` ${name}`}</span>
            </Col>

            <Col
              span={24}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <span style={{ fontSize: 20, color: 'red', marginRight: 10 }}>
                <ExclamationCircleOutlined />
              </span>
              <span>Add a CNAME record with host:</span>
            </Col>

            <Col span={24}>
              <CopyToClipboard
                text={`_acme-challenge.${String(name)}`}
                onCopy={() => Notification({ title: 'Successfuly copied to clipboard' })}>
                <button
                  type="button"
                  ref={refAcmeHostname}
                  style={{
                    padding: '2px 10px',
                    cursor: 'pointer',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  {`_acme-challenge.${String(name)}`}
                  <Icon name="copy" style={{ marginLeft: 10, fontSize: 18 }} />
                </button>
              </CopyToClipboard>
            </Col>

            <Col span={24}>that points to</Col>
            <Col span={24}>
              <CopyToClipboard
                text="_acme-challenge.xdn-validation.com"
                onCopy={() => Notification({ title: 'Successfuly copied to clipboard' })}>
                <button
                  type="button"
                  ref={refAcmeConst}
                  style={{
                    padding: '2px 10px',
                    cursor: 'pointer',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  _acme-challenge.xdn-validation.com
                  <Icon name="copy" style={{ marginLeft: 10, fontSize: 18 }} />
                </button>
              </CopyToClipboard>
            </Col>
            <Col span={24}>
              <Link to="/">More information</Link>
            </Col>
          </Row>
        </Drawer>
      );
    },
    [onClose, rederDrawerFooter, visibleDrawer]
  );

  return (
    <Row gutter={[20, 20]}>
      {renderConfiguration(hostNameConfigurationName)}
      <>
        <Col span={24} key={123}>
          <div
            // minHeight="initial"
            // padding="16px 24px"
            className="ProjectOriginTLSView"
            // isSkeleton={!data || isDataLoading}
          >
            <Row align="middle">
              <>
                {isTlsCertificatesLoading ? (
                  <Collapse collapsible="header" defaultActiveKey={['1']} key={0}>
                    <Panel header={collapseHeaderLoading()} key={0} />
                  </Collapse>
                ) : [...(tlsCertificates?.data?.properties || [])].length < 1 ? (
                  <Collapse collapsible="header" activeKey={['1']} defaultActiveKey={['1']} key={0}>
                    <Panel header={collapseHeader('notActive', '')} key={0} />
                  </Collapse>
                ) : (
                  tlsCertificates?.data?.properties.map((certif, index) => {
                    if (index === 0) {
                      return (
                        <Collapse collapsible="header" defaultActiveKey={['0']} key={certif.id}>
                          <Panel
                            header={collapseHeader(certif.status, certif.expiration)}
                            key={certif.id}>
                            <div
                              style={{
                                backgroundColor: 'black',
                                position: 'relative',
                                color: '#fff',
                                padding: 5,
                                borderRadius: 5,
                              }}>
                              <div>
                                <code>
                                  Certificate CN: {certif.common_name}
                                  <br />
                                  Certificate SAN: {certif.alternative_names.join(', ')}
                                  <br />
                                  Certificate serial: {certif.serial}
                                  <br />
                                  Certificate expiration: {certif.expiration}
                                  <br />
                                  Certificate created: {certif.created_at}
                                </code>
                              </div>
                              <div style={{ position: 'absolute', top: 5, right: 5 }}>
                                <Tooltip placement="top" title="Copy to clipboard">
                                  <CopyToClipboard
                                    text={`
    Certificate CN: ${certif.common_name}\n
    Certificate SAN: ${certif.alternative_names.join(', ')}\n
    Certificate serial: ${certif.serial}\n
    Certificate expiration: ${certif.expiration}\n
    Certificate expiration: ${certif.expiration}\n`}
                                    onCopy={() =>
                                      Notification({ title: 'Successfuly copied to clipboard' })
                                    }>
                                    <Icon
                                      className="copyIcon"
                                      name="copy"
                                      style={{ fontSize: 20 }}
                                    />
                                  </CopyToClipboard>
                                </Tooltip>
                              </div>
                            </div>
                          </Panel>
                        </Collapse>
                      );
                    }
                    return null;
                  })
                )}
              </>
            </Row>
            <Row align="middle" gutter={2} style={{ margin: '20px 0px' }}>
              <Col>
                <Switch
                  checked={tlsAutomatically}
                  onChange={(e) => {
                    setTlsAutomatically(e);

                    if (!tlsAutomatically) {
                      setPrimaryCert('');
                      setIntermediateCert('');
                      setPrivateKeyCert('');
                    }
                  }}
                />
              </Col>
              <Col>
                <span style={{ marginLeft: 10 }}>
                  Automatically create an TLS certificate for my custom domains.
                </span>
              </Col>
            </Row>
            {tlsAutomatically ? certificatesTable : customCertificate}

            <Row
              align="middle"
              gutter={2}
              style={{
                marginTop: '32px',
              }}>
              <Col span={24}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ fontSize: 20, marginRight: 10 }}>
                    <ClockCircleOutlined />
                  </span>
                  <span>Propagation of the certificate to the network may take up to 6 hours.</span>
                </div>
              </Col>
              <Col span={24} style={{ marginTop: 10 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ fontSize: 20, marginRight: 10 }}>
                    <WarningOutlined />
                  </span>
                  <span>Only TLSv1.3 and TLSv1.2 are supported.</span>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </>
    </Row>
  );
};

export default ProjectOriginTLSView;
