/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import './ProjectOriginOrigin.scss';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row, Skeleton } from 'antd';
import { propertyAPI } from 'services';
import { useAppDispatch, useAppSelector } from 'store';
import { isEqual, size } from 'lodash';
import { useParams } from 'react-router-dom';
import Button from 'components/common/Button';
import OriginsRow from 'components/views/home/HomeAddModalNew/OriginsRow';
import { Origin } from 'models';
import { updateOriginsDetails } from 'store/slices/property';
import { getSkeletonsSize, updateSkeletonsSize } from 'helpers/utils';

const defaultOrigin = {
  name: '',
  hosts: [
    {
      scheme: 'match',
      location: [
        {
          hostname: '',
        },
      ],
    },
  ],
  balancer: 'primary_failover',
  tls_verify: {
    use_sni: false,
    allow_self_signed_certs: false,
  },
  shields: {
    apac: 'bypass',
    emea: 'bypass',
    us_east: 'bypass',
    us_west: 'bypass',
  },
};

export const ProjectOriginOrigin: FC = () => {
  const dispatch = useAppDispatch();

  const { origins, hostnames } = useAppSelector(({ property }) => property);
  const { directoryName } = useParams<{ directoryName?: string }>();
  const { token } = useAppSelector(({ auth }) => auth);

  const [skeletons] = useState(getSkeletonsSize('nOfOrs', String(directoryName)));

  const {
    data: propertyDetails,
    isLoading: isPropertyLoading,
    // isFetching: isPropertyFetching,
  } = propertyAPI.useFetchPropertyQuery(String(directoryName), {
    skip: !token,
  });

  const [originsData, setOriginsData] = useState<Origin[]>(origins);

  useEffect(() => {
    setOriginsData((prev) => {
      if (isEqual(prev, origins)) {
        return prev;
      }
      return origins;
    });
  }, [origins, propertyDetails?.data?.origins]);

  useEffect(() => {
    dispatch(updateOriginsDetails(originsData));
    if (size([...originsData])) {
      updateSkeletonsSize('nOfOrs', String(directoryName), size([...(originsData ?? [])]));
    }
  }, [directoryName, dispatch, originsData]);

  const notUsedNames = useMemo(() => {
    if (originsData && hostnames) {
      const a = originsData.map((i) => i.name);
      const b = hostnames.map((i) => i.default_origin_name);

      return a.filter((element) => !b.includes(element));
    }
    return [];
  }, [hostnames, originsData]);

  const handleChangeHost = useCallback(
    (val: Origin | undefined, id: number) => {
      if (val === undefined) {
        setOriginsData((prevState) => {
          const newOrigins = [...prevState];
          newOrigins.splice(id, 1);

          return newOrigins;
        });
      } else {
        setOriginsData((prevState) =>
          prevState.map((i, indx) => {
            if (indx === id) {
              return val;
            }
            return i;
          })
        );
      }
    },
    [setOriginsData]
  );

  const handleAddHost = useCallback(() => {
    setOriginsData((prev) => [...(prev ?? []), defaultOrigin]);
  }, [setOriginsData]);

  return (
    <div className="ProjectOriginOrigin" style={{ maxHeight: 'none' }}>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <div>
            <Row gutter={[20, 20]}>
              <Col span={24} style={{ fontSize: '14px', fontWeight: 600 }}>
                Here you can configure origin hosts, load balancing, and origin TLS parameters. In
                order to ensure your origins accept connections from the CDN, follow these
                instructions
              </Col>

              <Col span={24}>
                {isPropertyLoading ? (
                  Array.from({ length: skeletons }).map((i, id) => (
                    <Skeleton.Button
                      active
                      size="large"
                      block
                      key={String(i) + id}
                      style={{ margin: '5px 0' }}
                    />
                  ))
                ) : originsData.length > 0 ? (
                  originsData.map((item, ind) => (
                    <div style={{ margin: '5px 0' }} key={item.name + ind}>
                      <OriginsRow
                        isOrigin
                        notUsedNames={notUsedNames}
                        allUsedNames={originsData.map((i) => i.name)}
                        isRequierdField={false}
                        vals={item}
                        key={item.name + ind}
                        id={ind}
                        onChangeName={() => {}}
                        onHostChange={(val, id) => handleChangeHost(val, id)}
                      />
                    </div>
                  ))
                ) : propertyDetails?.data?.origins.length === 0 || isPropertyLoading ? (
                  <Row
                    gutter={32}
                    className="ProjectOriginCdnCnames__table-head"
                    justify="center"
                    align="middle">
                    <Col className="ProjectOriginCdnCnames__head-col _ta-left">
                      {propertyDetails?.data?.origins.length === 0 &&
                        `You don't have any origins yet.`}
                    </Col>
                  </Row>
                ) : (
                  Array.from({ length: skeletons }).map((i, id) => (
                    <Skeleton.Button
                      active
                      size="large"
                      block
                      key={String(i) + id}
                      style={{ margin: '5px 0' }}
                    />
                  ))
                )}
              </Col>
            </Row>

            <Row gutter={32} align="middle">
              <Col span={7} style={{ marginTop: '20px' }}>
                <Button icon="plus" type="secondary" onClick={handleAddHost}>
                  Add Origin
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ProjectOriginOrigin;
