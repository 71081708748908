/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row, Skeleton } from 'antd';
import { Notification } from 'components/common/Notification';
import { IPropertySecurityProfilesData, IPropertySecurityProfilesUpdate } from 'models/service';
import { sucurityProfilesServiceAPIv2 } from 'services';
import { useAppSelector } from 'store';
import { size } from 'lodash';
import { getSkeletonsSize, updateSkeletonsSize } from 'helpers/utils';
import Button from 'components/common/Button';
// import BannerDeployChanges from 'components/common/BannerDeployChanges';
import { SecurityItem } from './SecurityItem';
import styles from './ProjectOriginSecurityView.module.scss';
import './ProjectOriginSecurityView.scss';

export const ProjectOriginSecurityView: FC = () => {
  const { directoryName } = useParams<{ directoryName?: string }>();
  const { token } = useAppSelector(({ auth }) => auth);
  const [skeletons] = useState(getSkeletonsSize('sepr', String(directoryName)));
  const [securityProfiles, setSecurityProfiles] = useState<IPropertySecurityProfilesUpdate[]>([]);
  const [isPageDisabled, setIsPageDisabled] = useState<boolean>(false);

  const [updatedSecurityProfilesData, setUpdatedSecurityProfiles] = useState<
    IPropertySecurityProfilesUpdate[]
  >([]);
  const [statusProfiles, setStatusProfiles] = useState<string>('active');
  // const [isVisible, setIsVisible] = useState(false);
  const [isRevert, setIsRevert] = useState(false);

  const { data: securityProfilesData, isLoading: isLoadingSecurityProfilesData } =
    sucurityProfilesServiceAPIv2.useFetchSecurityProfilesQuery(String(directoryName), {
      skip: !token,
      pollingInterval: statusProfiles === 'in_progress' ? 3000 : 0,
    });

  const hasInProgress = useMemo(
    () => securityProfilesData?.data?.some((item) => item.details.status === 'in_progress'),
    [securityProfilesData?.data]
  );

  const normalizedData: IPropertySecurityProfilesUpdate[] = useMemo(
    () =>
      [...(securityProfilesData?.data || ([] as IPropertySecurityProfilesData[]))].map((item) => ({
        hostname: item.hostname,
        options: item.details.configuration_data,
      })),
    [securityProfilesData?.data]
  );

  const isSaveBtnDisabled = useMemo(
    () =>
      JSON.stringify(
        securityProfilesData?.data?.map((item) => ({
          hostname: item.hostname,
          options: item.details.configuration_data,
        }))
      ) === JSON.stringify(updatedSecurityProfilesData),
    [securityProfilesData, updatedSecurityProfilesData]
  );

  useEffect(() => {
    // let timer: NodeJS.Timeout;

    const intervalId = setInterval(() => {
      setIsPageDisabled(document.querySelector('._deployBanner') !== null);
    }, 100);

    // if (securityProfilesData?.success) {
    //   timer = setTimeout(() => {
    //     setIsVisible(true);
    //   }, 100);
    // }

    return () => {
      // clearTimeout(timer);
      clearInterval(intervalId);
    };
  }, [securityProfilesData?.success]);

  useEffect(() => {
    if (hasInProgress) {
      setStatusProfiles('in_progress');
    } else {
      setStatusProfiles('active');
    }
  }, [hasInProgress]);

  useEffect(() => {
    if (securityProfilesData?.data) {
      if (isRevert) {
        setSecurityProfiles([]);
      } else {
        setSecurityProfiles(normalizedData);
      }
      setUpdatedSecurityProfiles(normalizedData);
      updateSkeletonsSize('sepr', String(directoryName), size(securityProfilesData.data));
    }

    const timer = setTimeout(() => {
      setIsRevert(false);
    }, 700);

    return () => {
      clearTimeout(timer);
    };
  }, [directoryName, isRevert, normalizedData, securityProfilesData?.data]);

  const getUpdatedData = useCallback((securityProfile: IPropertySecurityProfilesUpdate) => {
    setUpdatedSecurityProfiles((prevData) =>
      prevData.map((data) =>
        data.hostname === securityProfile.hostname
          ? { ...data, options: securityProfile.options }
          : data
      )
    );
  }, []);

  const [updateSecurityProfiles, { isLoading: isUpdateSecurityProfiles }] =
    sucurityProfilesServiceAPIv2.useUpdateSeccurityProfilesMutation();

  const handleSaveSecurity = useCallback(() => {
    const propertyId = String(directoryName);

    updateSecurityProfiles({ propertyId, hostnames: updatedSecurityProfilesData })
      .unwrap()
      .then((respData) => {
        Notification({
          type: 'check',
          title: 'Changes saved successfully!',
          message: respData.message,
        });
      })
      .catch((error) => {
        const errorMessage = error.data.data
          ? Object.values(error.data.data)
              .flat(2)
              .map((item: any, idx) => (
                <Col span={24} key={idx} style={{ margin: '3px 0' }}>
                  <span>{item}</span>
                </Col>
              ))
          : error.data.message;
        Notification({ type: 'cross', title: 'Error', message: errorMessage });
      });
  }, [directoryName, updateSecurityProfiles, updatedSecurityProfilesData]);

  // const handleRevert = useCallback(() => {
  //   setIsRevert(true);
  //   if (securityProfilesData?.data) {
  //     setUpdatedSecurityProfiles(normalizedData);
  //   }
  // }, [normalizedData, securityProfilesData?.data]);

  const renderSecuritySkeletonRow = (indx: number) => (
    <Row
      key={indx}
      className="ProjectOriginSecurityView__table-row"
      style={{ padding: '19px 20px', marginBottom: 0 }}>
      <Skeleton.Button active size="small" block />
    </Row>
  );

  const renderNoActiveHostnamesMessage = () => (
    <Row
      key={0}
      className="ProjectOriginSecurityView__table-row"
      style={{ padding: '12px 25px', marginBottom: 0 }}>
      <div style={{ color: 'gray', fontSize: 12, lineHeight: '24px' }}>
        You don&apos;t have any active hostnames yet
      </div>
    </Row>
  );

  const renderSecurityProfiles = () =>
    securityProfiles.map((item: IPropertySecurityProfilesUpdate, idx) => (
      <SecurityItem
        key={`${item.hostname}-${idx}`}
        hostname={item.hostname}
        configurationData={item.options}
        getUpdatedData={getUpdatedData}
        isPageDisabled={isPageDisabled}
      />
    ));

  const renderSkeletons = () =>
    Array.from({ length: skeletons }).map((_, indx) => renderSecuritySkeletonRow(indx));

  const renderContent = () => {
    if (isLoadingSecurityProfilesData || isRevert || !securityProfiles) {
      return renderSkeletons();
    }

    if (!size(securityProfiles)) {
      return renderNoActiveHostnamesMessage();
    }

    return renderSecurityProfiles();
  };

  return (
    <>
      {/* {(isVisible && !isSaveBtnDisabled) || hasInProgress ? (
        <BannerDeployChanges
          withOutDiff
          btnTitle="Save Changes"
          title="You have unsaved changes"
          deployLoading={isUpdateSecurityProfiles}
          onDeployClick={handleSaveSecurity}
          onRevertClick={handleRevert}
          status={statusProfiles}
          isDisabled={isLoadingSecurityProfilesData || isSaveBtnDisabled}
          // style={{ position: 'relative', bottom: '14px' }}
        />
      ) : null} */}

      <Row gutter={[20, 20]}>
        <Col span={24}>
          <div className={styles.securityProfileWrapper}>
            <div>
              <h4 style={{ fontSize: '14px', fontWeight: 600 }}>
                Configure your security strategy tailored to each specific hostname here.
              </h4>
            </div>
            <Row className="ProjectOriginSecurityView__table-head">
              <Col span={8} className="ProjectOriginSecurityView__head-col _ta-left">
                <span className="ProjectOriginSecurityView__head-label">HOSTNAME</span>
              </Col>
              <Col span={5} className="ProjectOriginSecurityView__head-col _ta-left">
                <span className="ProjectOriginSecurityView__head-label">WAF</span>
              </Col>
              <Col span={5} className="ProjectOriginSecurityView__head-col _ta-left">
                <span className="ProjectOriginSecurityView__head-label">RATE LIMITING</span>
              </Col>
              <Col span={5} className="ProjectOriginSecurityView__head-col _ta-left">
                <span className="ProjectOriginSecurityView__head-label">BOT PROTECTION</span>
              </Col>
              <Col span={1} className="ProjectOriginSecurityView__head-col _ta-left">
                <span className="ProjectOriginSecurityView__head-label" />
              </Col>
            </Row>
            <>{renderContent()}</>
          </div>
        </Col>

        {!(isLoadingSecurityProfilesData || isRevert || !securityProfiles) && (
          <Col span={24}>
            <Row justify="start" gutter={32}>
              <Col>
                <Button
                  onClick={handleSaveSecurity}
                  loading={isUpdateSecurityProfiles}
                  disabled={isLoadingSecurityProfilesData || isSaveBtnDisabled || hasInProgress}>
                  {hasInProgress ? 'Deploying...' : 'Save Changes'}
                </Button>
              </Col>
              {/* <Col>
                {!hasInProgress && !hasInProgress && (
                  <Button
                    onClick={handleRevert}
                    loading={isUpdateSecurityProfiles}
                    disabled={isSaveBtnDisabled}>
                    Revert
                  </Button>
                )}
              </Col> */}
            </Row>
          </Col>
        )}
      </Row>
    </>
  );
};

export default ProjectOriginSecurityView;
