import { createApi } from '@reduxjs/toolkit/query/react';
import { IFailoverGroup } from 'models';
import { endpoints } from '../urls';
import { baseQuery } from './baseV2';

export const routingAPI = createApi({
  reducerPath: 'routingAPI',
  baseQuery: baseQuery(),
  tagTypes: ['DNS'],
  endpoints: (build) => ({
    fetchRouting: build.query<IFailoverGroup, { directoryName: string }>({
      query: ({ directoryName }) => ({
        url: endpoints.v2.dns.dns(directoryName),
      }),
      providesTags: ['DNS'],
    }),
    createZone: build.mutation<IFailoverGroup, { directoryName: string; DomainName: string }>({
      query: ({ directoryName, DomainName }) => ({
        url: endpoints.v2.dns.dns(directoryName),
        method: 'POST',
        params: {
          DomainName,
        },
      }),
      invalidatesTags: () => ['DNS'],
    }),
    updateRouting: build.mutation<
      IFailoverGroup,
      { directoryName: string; id: string; body: IFailoverGroup }
    >({
      query: ({ directoryName, id, body }) => ({
        url: `${endpoints.v2.dns.dns(directoryName)}/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['DNS'],
    }),
    deleteRouting: build.mutation<IFailoverGroup, { directoryName: string; id: number }>({
      query: ({ directoryName, id }) => ({
        url: `${endpoints.v2.dns.dns(directoryName)}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['DNS'],
    }),
  }),
});

export default routingAPI;
