import './Input.scss';
import { Input as AInput, Tooltip } from 'antd';
import React, { FC, useState, TextareaHTMLAttributes, ChangeEventHandler, ReactNode } from 'react';
import classNames from 'classnames';
import { Icon, IconType } from '../Icon';

export interface Props {
  className?: string;
  onChange?:
    | TextareaHTMLAttributes<HTMLTextAreaElement>['onChange']
    | ChangeEventHandler<HTMLInputElement>;
  width?: number | string;
  height?: number | string;
  label?: string;
  placeholder?: string;
  icon?: IconType;
  tooltip?: string;
  disabled?: boolean;
  password?: boolean;
  autoComplete?: string;
  isBig?: boolean;
  value?: string;
  addonBefore?: string;
  addonAfter?: string;
  isRequired?: boolean;
  textarea?: boolean;
  suffix?: ReactNode;
  onFocus?: () => void;
  onBlur?: () => void;
}

export const Input: FC<Props> = ({
  onChange,
  label,
  placeholder,
  className,
  width = '100%',
  height = 'auto',
  icon,
  tooltip,
  disabled,
  password,
  autoComplete,
  isBig,
  value,
  addonBefore,
  addonAfter,
  isRequired = false,
  textarea,
  suffix,
  onFocus,
  onBlur,
}) => {
  const [focused, setFocused] = useState(false);

  return (
    <div className={classNames('Input', className)}>
      {label && !tooltip && (
        <label className={classNames('Input__label', { _big: isBig })}>
          {isRequired ? (
            <span>
              {label}
              <span className="Input__label-required">Required</span>
            </span>
          ) : (
            label
          )}
        </label>
      )}
      {label && tooltip && (
        <label className={classNames('Input__label', { _big: isBig })}>
          {label}
          <Tooltip title={tooltip}>
            <Icon name="info-in-circle" className="Input__tooltip-icon" />
          </Tooltip>
        </label>
      )}
      {!password ? (
        <>
          {textarea ? (
            <AInput.TextArea
              style={{ width, height }}
              placeholder={placeholder}
              onChange={onChange as TextareaHTMLAttributes<HTMLTextAreaElement>['onChange']}
              className={classNames('Input__input', {
                _big: isBig,
                _focused: focused && !disabled,
                _textarea: textarea,
              })}
              disabled={disabled}
              autoComplete={autoComplete}
              value={value}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
            />
          ) : (
            <AInput
              prefix={icon && <Icon name={icon} className="Input__icon" />}
              style={{ width }}
              placeholder={placeholder}
              onChange={onChange as ChangeEventHandler<HTMLInputElement>}
              className={classNames('Input__input', {
                _big: isBig,
                _focused: focused && !disabled,
              })}
              disabled={disabled}
              autoComplete={autoComplete}
              value={value}
              addonBefore={addonBefore}
              addonAfter={addonAfter}
              onFocus={() => {
                setFocused(true);
                if (onFocus) {
                  onFocus();
                }
              }}
              onBlur={() => {
                setFocused(false);
                if (onBlur) {
                  onBlur();
                }
              }}
              suffix={suffix}
            />
          )}
        </>
      ) : (
        <AInput.Password
          style={{ width }}
          placeholder={placeholder}
          onChange={onChange as ChangeEventHandler<HTMLInputElement>}
          className={classNames('Input__input', { _big: isBig })}
          disabled={disabled}
          autoComplete={autoComplete}
          iconRender={(visible) =>
            visible ? (
              <Icon name="eye" style={{ fontSize: 20 }} />
            ) : (
              <Icon name="visibility" style={{ fontSize: 20 }} />
            )
          }
        />
      )}
    </div>
  );
};
