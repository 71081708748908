import React, { FC } from 'react';
import { Col, Row } from 'antd';
import Button from 'components/common/Button';
import { Icon } from 'components/common/Icon';
import { Loader } from 'components/common/Loader';
import { ProjectSecurityAccessControl } from 'components/views/Project/ProjectSecurity';
import { userDetailsApi } from 'services';
import { useAppSelector } from 'store';
import './ProjectOriginAccessControl.scss';

export const ProjectOriginAccessControl: FC = () => {
  const { token } = useAppSelector(({ auth }) => auth);

  const { data } = userDetailsApi.useFetchMeDetailsQuery('', {
    skip: !token,
  });

  if (data?.data) {
    return (
      <Row justify="center" align="middle">
        {data?.data?.acl ? (
          <Col span={24}>
            <ProjectSecurityAccessControl />
          </Col>
        ) : (
          <div
            style={{
              position: 'absolute',
              top: '10%',
            }}>
            <Col span={24} style={{ width: 800 }}>
              <Row justify="center">
                <Col className="acl-title">Upgrade to Unlock Full Functionality</Col>
              </Row>
              <Row justify="center">
                <Col className="acl-subtitle1">
                  Currently, your account does not have access to the Access Control feature.
                </Col>
                <Col className="acl-subtitle2">
                  With Access Control, you can enhance the security of your web applications by
                  adding IP addresses to white and black lists, giving you greater control over who
                  can access your resources.
                </Col>
              </Row>

              <Row justify="center">
                <Col className="acl-subtitle3">Why Upgrade to Premium?</Col>
              </Row>
              <Row justify="center">
                <Col span={6}>
                  <Row justify="center" className="acl-icon">
                    <Icon style={{ fontSize: 32 }} name="vpnLock" />
                  </Row>
                  <Row justify="center" className="acl-icon-title">
                    Enhanced Security
                  </Row>
                  <Row className="acl-icon-description">
                    Protect your application from unauthorized access by allowing only trusted IP
                    addresses.
                  </Row>
                </Col>
                <Col span={6}>
                  <Row justify="center" className="acl-icon">
                    <Icon style={{ fontSize: 32 }} name="speed" />
                  </Row>
                  <Row justify="center" className="acl-icon-title">
                    Improved Performance
                  </Row>
                  <Row className="acl-icon-description">
                    Block malicious traffic and reduce load on your servers, improving response
                    times for legitimate users.
                  </Row>
                </Col>
                <Col span={6}>
                  <Row justify="center" className="acl-icon">
                    <Icon style={{ fontSize: 32 }} name="settings" />
                  </Row>
                  <Row justify="center" className="acl-icon-title">
                    Customizability
                  </Row>
                  <Row className="acl-icon-description">
                    Tailor your security settings to meet specific needs by managing access at a
                    granular level.
                  </Row>
                </Col>
              </Row>
              <Row justify="center" className="button-block">
                <Button type="danger">
                  <Row style={{ minWidth: '290px' }} justify="center" align="middle">
                    <Icon name="crown" style={{ fontSize: 18, paddingRight: 8 }} />
                    <span className="button-text">Upgrade to Premium</span>
                  </Row>
                </Button>
              </Row>
            </Col>
          </div>
        )}
      </Row>
    );
  }
  return (
    <Row justify="center" align="middle">
      <Col>
        <Loader />
      </Col>
    </Row>
  );
};

export default ProjectOriginAccessControl;
