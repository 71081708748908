import React, { FC, useCallback } from 'react';
import { Col, Row, Tooltip } from 'antd';
import classNames from 'classnames';
import Button from 'components/common/Button';
import { Icon } from 'components/common/Icon';
import { Label } from 'components/common/Label';
import StatusDot from 'components/common/StatusDot/StatusDot';
import { Typography } from 'components/common/Typography';
import { capitalize, findIndex, round } from 'lodash';
import { IProperty, ISummaryStatsItem } from 'models';
import { generatePath, useNavigate } from 'react-router-dom';
import RouteTypes from 'router/types';
import { useAppDispatch, useAppSelector } from 'store';
import { updateNavigation } from 'store/slices/ui';

interface Props {
  item: IProperty;
  isLast?: boolean;
  isGridView?: boolean;
  stats?: ISummaryStatsItem;
  onStarClick: (favorite: boolean, propertyId: string) => void;
}

export const HomeTableTablePropertyItem: FC<Props> = ({
  item,
  isLast,
  isGridView,
  stats,
  onStarClick,
}) => {
  const navigate = useNavigate();
  const { navigation } = useAppSelector(({ ui }) => ui);
  const dispatch = useAppDispatch();

  const getLabelStatus = useCallback((value: string) => {
    switch (value) {
      case 'failed':
        return 'danger';
      case 'deployed':
        return 'success';
      case 'deploying':
        return 'warning';
      default:
        return 'info';
    }
  }, []);

  const onDirrectoryClick = useCallback(
    (itemData: IProperty) => {
      const { id, name } = itemData;

      const idx = findIndex(navigation, ['directoryName', id]);
      if (idx === -1) {
        dispatch(updateNavigation({ name, directoryName: id }));
      }
      navigate(generatePath(RouteTypes.project, { directoryName: String(id) }));
    },
    [dispatch, navigate, navigation]
  );

  return (
    <>
      {isGridView ? (
        <Col span={6}>
          <div className="HomeTable__card">
            <div className="HomeTable__card-head">
              <Row justify="space-between" align="middle">
                <Col>
                  <Tooltip
                    placement="bottom"
                    title={!item?.is_favorite ? 'Add to favorites' : 'Remove from favorites'}>
                    <Icon
                      onClick={() => onStarClick(item.is_favorite, item.id)}
                      className={classNames('HomeTable__head-icon _big', {
                        _yellow: item?.is_favorite,
                      })}
                      // name="star-empty"
                      name={!item?.is_favorite ? 'star-empty' : 'star-full'}
                    />
                  </Tooltip>
                </Col>
                <Col>
                  <Row gutter={8}>
                    {/* {Number(item?.percent_propagated) < 100 && (
                      <Col style={{ width: 120 }}>
                        <Progress
                          strokeColor="#0079d3"
                          width={100}
                          percent={Number(item?.percent_propagated)}
                          status="active"
                        />
                      </Col>
                    )} */}
                    <Col>
                      <Label type={getLabelStatus(String(item?.status))}>
                        {item?.status === 'propagated' ? 'Active' : capitalize(item?.status)}
                      </Label>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="HomeTable__card-chart">
              {/* <HomeTableTableItemChart data={chartData?.data} item={item} isLoading={isLoading} /> */}
            </div>
            <Row
              justify="start"
              className="HomeTable__card-footer"
              aria-hidden="true"
              onClick={() => item && onDirrectoryClick(item)}>
              <Col>
                <Col span={24}>
                  <span
                    className={classNames('HomeTable__head-name _dark')}
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: 'block',
                    }}>
                    {item?.name}
                  </span>
                </Col>
                <Col>
                  <Button type="subtle" className="domainName">
                    <Tooltip placement="bottom" title={item?.dns_domain_name}>
                      <div className="domainNameText">{item?.dns_domain_name || ''}</div>
                    </Tooltip>
                  </Button>
                </Col>
              </Col>
              <Col>
                <Row gutter={4} align="middle">
                  <Col>
                    <StatusDot />
                  </Col>
                  <Col>
                    <span style={{ fontSize: '14px', fontWeight: 400 }}>
                      {round(Number(stats?.requests_s || 0), 2)} R/s
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      ) : (
        <Col
          className={classNames('HomeTable__item', {
            _last: isLast,
          })}
          span={24}
          key={item?.id}>
          <div className="HomeTable__item-list">
            <Row gutter={20} className="HomeTable__item-row">
              <Col className={classNames('HomeTable__item-col', 'HomeTable__item-icon')} span={1}>
                <Tooltip
                  placement="bottom"
                  title={!item?.is_favorite ? 'Add to favorites' : 'Remove from favorites'}>
                  <Icon
                    onClick={() => onStarClick(item.is_favorite, item.id)}
                    className={classNames('HomeTable__head-icon _big', {
                      _yellow: item?.is_favorite,
                    })}
                    // name="star-empty"
                    name={!item?.is_favorite ? 'star-empty' : 'star-full'}
                  />
                </Tooltip>
              </Col>
              <Col className="HomeTable__item-info" onClick={() => item && onDirrectoryClick(item)}>
                <Col className="HomeTable__item-col" span={6}>
                  <Typography variant="span" className={classNames('HomeTable__head-name _dark')}>
                    {item?.name}
                  </Typography>
                </Col>
                <Col className="HomeTable__item-col" span={8}>
                  <Button type="subtle">{item?.dns_domain_name || ''}</Button>
                </Col>
                <Col className="HomeTable__item-col" span={6}>
                  <Row gutter={8}>
                    <Col>
                      <Label type={getLabelStatus(String(item?.status))}>
                        {item?.status === 'propagated' ? 'Active' : capitalize(item?.status)}
                      </Label>
                    </Col>

                    {/* {Number(item?.percent_propagated) < 100 && (
                      <Col style={{ width: 120 }}>
                        <Progress
                          strokeColor="#0079d3"
                          width={100}
                          percent={Number(item?.percent_propagated)}
                          status="active"
                        />
                      </Col>
                    )} */}
                  </Row>
                </Col>
                <Col className="HomeTable__item-col _last" flex={1}>
                  <StatusDot />
                  <Typography>{round(Number(stats?.requests_s || 0), 2)} R/s</Typography>
                </Col>
              </Col>
            </Row>
          </div>
        </Col>
      )}
    </>
  );
};

export default HomeTableTablePropertyItem;
