import {
  IRealtimeStatisticPayload,
  IRealtimeStatisticPayloadMap,
} from 'services/RealtimeStatisticService';

export const endpoints = {
  // ---===NEW ENDPOINTS===---
  v2: {
    accessControl: {
      getControlList: (envId: string) => `/access-control-list/${envId}`,
      updateControlList: (envId: string) => `/access-control-list/${envId}`,
    },

    dns: {
      dns: (envId: string) => `/environments/${envId}/dns`,
      dnsItem: (envId: string, dnsId: string) => `/environments/${envId}/dns${dnsId}`,
    },

    deployments: {
      getAllDeployments: (propertyId: string) => `/deployments/${propertyId}`,
      getDeploymentDetails: (propertyId: string) => `/deployment/${propertyId}`,
    },

    favorites: {
      getAllFavorites: `/all-favorites`,
      addToFavorites: (propertyId: string) => `/add-to-favorites/${propertyId}`,
      removeFromFavorites: (propertyId: string) => `/remove-from-favorites/${propertyId}`,
    },

    account: {
      billingReports: '/users/billing-report',
      getLastViewedProperties: '/users/last-viewed-properties',
      getInvoices: '/users/invoices/reports',
    },

    analytics: {
      historicalData: (type: 5 | 15 | 60 | 1440, propertyId: string) =>
        `/historical-data/${type}/${propertyId}`,
      searchLogs: (propertyId: string) => `/log/${propertyId}`,
      aggregateLogs: (propertyId: string) => `/log/aggregate/${propertyId}`,
      realTimeStat: (propertyId: string) => `/liveport/${propertyId}`,
      realTimePopStat: (propertyId: string) => `/liveport/pop/${propertyId}`,
      timeseriesStat: (propertyId: string, type: 'rate-limiting' | 'event' | 'bot') =>
        `/time-series/${propertyId}/${type}`,
      topTen: (propertyId: string, type: 'events' | 'rate-limiting' | 'bot') =>
        `/top-ten/${type}/${propertyId}`,
      topEntries: (propertyId: string, type: 'event' | 'rate-limiting' | 'bot') =>
        `/top-entries/${type}/${propertyId}`,
    },

    properties: {
      properties: '/properties',
      property: (propertyId: string) => `/properties/${propertyId}`,
      purgeAllProperty: (propertyId: string) => `/properties/${propertyId}/purge/all`,
      purgeCustomProperty: (propertyId: string) => `/properties/${propertyId}/purge/custom`,
      domainsTLSProperty: (propertyId: string) => `/properties/${propertyId}/tls/domains-list`,
      generateTlsCertificates: (propertyId: string) => `/properties/${propertyId}/tls/generate`,
      uploadTLSCertificate: (propertyId: string) => `/properties/${propertyId}/tls/upload`,
      allTlsCertificates: (propertyId: string) => `/properties/${propertyId}/tls/all-certificates`,
      changeOwner: (propertyId: string) => `/properties/${propertyId}/change-owner`,
      updateSecurityProfile: (propertyId: string) =>
        `/properties/${propertyId}/security-profiles/update`,
      securityProfile: (propertyId: string) =>
        `/properties/${propertyId}/security-profiles/history`,
      apikey: (propertyId: string) => `/properties/${propertyId}/apikey`,
      usersDelete: (propertyId: string, userId: string) =>
        `/properties/${propertyId}/users/${userId}/remove`,
      users: (propertyId: string) => `/properties/${propertyId}/users`,
    },

    invitations: {
      invitations: '/invitations',
      getInvitations: (propertyId: string) => `/invitations/${propertyId}`,
      invitationsResend: (propertyInvitationId: string) =>
        `/invitations/${propertyInvitationId}/resend`,
      deleteInvitation: (propertyInvitationId: string) => `/invitations/${propertyInvitationId}`,
    },
    securityProfiles: {
      getSecurityProfiles: (propertyId: string) => `/security-profiles/${propertyId}/activity`,
      updateSecurityProfiles: (propertyId: string) => `/security-profiles/${propertyId}/update`,
    },
  },
  // ---===NEW ENDPOINTS===---

  // ---===____________===---
  // ---===____________===---
  // ---===____________===---

  // ---===OLD ENDPOINTS===---
  collaboration: {
    users: (directoryName: string) => `/projects/${directoryName}/users`,
    changeOwner: (directoryName: string) => `/projects/${directoryName}/change-owner`,
    usersDelete: (directoryName: string, id: number) =>
      `/projects/${directoryName}/users/${id}/remove`,
    invitations: (directoryName: string) => `/projects/${directoryName}/invitations`,
    invitationsDetails: (directoryName: string, invitation: string) =>
      `/projects/${directoryName}/invitations/${invitation}`,
    invitationsResend: (directoryName: string, invitation: string) =>
      `/projects/${directoryName}/invitations/${invitation}/resend`,
  },
  projects: '/projects',
  projectsAddFavorites: (directoryName: string) => `/projects/${directoryName}/add-favorites`,
  projectsRemoveFavorites: (directoryName: string) => `/projects/${directoryName}/remove-favorites`,
  nameserverUpdate: (directoryName: string) => `/projects/${directoryName}/nameserver-check`,
  summaryStats: '/summary-stats',
  aplicationTypes: '/application-types',
  projectsPlan: (directoryName: string) => `/projects/${directoryName}/billing-plan`,
  projectsBW: (directoryName: string) => `/projects/${directoryName}/bandwidth`,
  projectsPreviewChart: (directoryName: string) => `/projects/${directoryName}/bandwidth-chart`,
  origin: {
    cname: '/origin/cname',
    purge: {
      all: (directoryName: string) => `/purge/${directoryName}/all`,
      url: (directoryName: string, cnameId: string) => `/purge/${directoryName}/${cnameId}`,
    },
  },
  statistics: {
    common: (frequency: 5 | 15 | 60 | 1440, cname: string) => `/${frequency}/${cname}`,
    countries: (frequency: 5 | 15 | 60 | 1440, cname: string) => `/${frequency}/${cname}/country`,
    events: (cname: string, type: 1 | 5 | 24) => `/events/${type}/${cname}`,
    dnsSummary: (frequency: string, cname: string) => `/dns-summary/${frequency}/${cname}`,
    rateLimit: (cname: string, type: 1 | 5 | 24) => `/rate-limiting/${type}/${cname}`,
    raw: (cname: string) => `/events/${cname}/raw`,
    rawTopTen: (cname: string) => `/events/${cname}/raw/top-ten`,
    rawRateLimiting: (cname: string) => `/rate-limiting/${cname}/raw`,
    rawRateLimitingTopTen: (cname: string) => `/rate-limiting/${cname}/raw/top-ten`,
    botManagement: (cname: string) => `/bot/${cname}/raw`,
    botManagementTopTen: (cname: string) => `/bot/${cname}/raw/top-ten`,
  },
  analytics: {
    eventsLogs: (directoryName: string, cnameId: string) =>
      `/projects/${directoryName}/cnames/${cnameId}/event-log`,
    rateLimiting: (directoryName: string, cnameId: string) =>
      `/projects/${directoryName}/cnames/${cnameId}/rate-limiting`,
    botMagementLogs: (directoryName: string, cnameId: string) =>
      `/projects/${directoryName}/cnames/${cnameId}/bot-log`,
  },
  realtimeStatistic: ({ dirrectoryName, edge_id, event }: IRealtimeStatisticPayload) =>
    `/liveport/${event}/${dirrectoryName}/${edge_id}`,
  realtimeStatisticAll: ({ dirrectoryName, edge_id }: IRealtimeStatisticPayload) =>
    `/liveport/${dirrectoryName}/${edge_id}`,
  realtimeStatisticMap: ({ dirrectoryName, edge_id }: IRealtimeStatisticPayloadMap) =>
    `/liveport/pop/${dirrectoryName}/${edge_id}`,
  ip: '/ip',
  adnCnames: {
    cnames: (dirrectoryName: string) => `/projects/${dirrectoryName}/adncnames`,
    cnamesChangePrimary: (dirrectoryName: string, id: string | number) =>
      `/projects/${dirrectoryName}/adncnames/${id}/primary`,
    cnamesChangeRedirect: (dirrectoryName: string, id: string | number) =>
      `/projects/${dirrectoryName}/adncnames/${id}/redirect`,
  },
  cdnCnames: {
    cnames: (dirrectoryName: string) => `/projects/${dirrectoryName}/cdncnames`,
  },
  apikey: (dirrectoryName: string) => `/projects/${dirrectoryName}/apikey`,
  routing: (dirrectoryName: string) => `/projects/${dirrectoryName}/dns`,
  paymentMethods: {
    loadStripeKey: 'billing-api-key',
    paymentMethods: '/payment-methods',
    createIntent: '/payment-methods/create-intent',
    deletePayment: (id: string | number) => `/payment-methods/${id}`,
    updateDefault: (id: string | number) => `/payment-methods/update-default/${id}`,
  },
  log: (directoryName: string, id: string | number) => `/log/${directoryName}/${id}`,
  logChart: (directoryName: string, id: string | number) => `/log/aggregate/${directoryName}/${id}`,
  aggregateLogs: (collection: string) => `/log/aggregate/${collection}?analysis=yes`,
  eventLogs: (directoryName: string) => `/event-logs/${directoryName}`,
  sslDeleteShared: (directoryName: string, domain: string) =>
    `/projects/${directoryName}/free-ssl/${domain}/delete`,
  sslShared: (directoryName: string) => `/projects/${directoryName}/free-ssl`,
  ssl: (directoryName: string) => `/projects/${directoryName}/ssl`,
  sslId: (directoryName: string, sslId: string | number) =>
    `/projects/${directoryName}/ssl/${sslId}`,
  sslValidateId: (directoryName: string, sslId: string | number) =>
    `/projects/${directoryName}/ssl/${sslId}/validate`,
  sslCancelId: (directoryName: string, sslId: string | number) =>
    `/projects/${directoryName}/ssl/cancel/${sslId}?apply=true`,
  user: {
    showUser: (userId: string | number) => `/users/${userId}`,
    revertActing: '/users/revert-acting',
    details: '/users/details',
    billingDetails: '/users/billing-details',
    intercom: '/intercom',
    me: 'users/me',
  },
  users: {
    subscriptions: '/users/subscriptions',
    lastViewed: '/users/last-viewed',
    favorites: '/users/favorites',
    billingReports: '/users/billing-report',
    getLastViewedProperties: '/users/last-viewed-properties',
    cancelTrial: '/users/cancel-trial',
  },
  resetPassword: '/dbconnections/change_password',
  deleteAccount: '/account-delete',
  invoices: {
    get: '/users/invoices/reports',
  },
  rules: {
    rules: (directoryName: string, cnameId: string) =>
      `/projects/${directoryName}/cnames/${cnameId}/rules`,
    rulesDetails: (directoryName: string, cnameId: string, ruleId: string) =>
      `/projects/${directoryName}/cnames/${cnameId}/rules/${ruleId}`,
    rulesLatest: (directoryName: string, cnameId: string) =>
      `/projects/${directoryName}/cnames/${cnameId}/rules/latest`,
    rulesDeploy: (directoryName: string, cnameId: string, ruleId: string) =>
      `/projects/${directoryName}/cnames/${cnameId}/rules/${ruleId}/deploy`,
    rulesCancelDeploy: (directoryName: string, cnameId: string, ruleId: string) =>
      `/projects/${directoryName}/cnames/${cnameId}/rules/${ruleId}/cancel-deploy`,
    predefinedRules: (directoryName: string, cnameId: string) =>
      `/projects/${directoryName}/cnames/${cnameId}/predefined-rules`,
  },
  waf: (directoryName: string, cnameId: string) =>
    `/projects/${directoryName}/cnames/${cnameId}/waf-detail`,
  whiteList: (directoryName: string, cnameId: string) =>
    `projects/${directoryName}/cnames/${cnameId}/white-list-ips`,
  blackList: (directoryName: string, cnameId: string) =>
    `projects/${directoryName}/cnames/${cnameId}/black-list-ips`,
};
// ---===OLD ENDPOINTS===---

export default endpoints;
