import './Select.scss';

import React, { FC, useRef } from 'react';
import { SelectValue } from 'antd/lib/select';
import cn from 'classnames';
import { map } from 'lodash';
import { Select as ASelect } from 'antd';
import { Icon } from '../Icon';

const { Option } = ASelect;

export const selectTestId = 'select';

export interface SelectItemType {
  name: string;
  value: string | number;
  image?: string;
  label?: string;
}

export interface Props {
  data: SelectItemType[];
  className?: string;
  onChange?: (value: SelectValue) => void;
  width?: number | string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  dropdownMatchSelectWidth?: boolean | number;
  defaultValue?: string;
  value?: SelectValue;
  showSearch?: boolean;
  isBig?: boolean;
  mode?: 'multiple' | 'tags';
}

export const Select: FC<Props> = ({
  className,
  onChange,
  width = '100%',
  label,
  data,
  placeholder,
  disabled = false,
  dropdownMatchSelectWidth = true,
  defaultValue,
  value,
  showSearch,
  isBig,
  mode = undefined,
}) => {
  const handleChange = (v: SelectValue) => {
    if (onChange) onChange(v);
  };
  const ref = useRef(null);

  return (
    <div className={cn('Select', className, { _big: isBig })} ref={ref}>
      {label && <label className="Select__label">{label}</label>}
      <ASelect
        showSearch={showSearch}
        optionFilterProp={showSearch ? 'children' : undefined}
        filterOption={
          showSearch
            ? (input, option) =>
                option?.children?.[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
            : undefined
        }
        mode={mode && mode}
        value={value}
        defaultValue={defaultValue}
        data-testid={selectTestId}
        disabled={disabled}
        popupClassName="Select__select-dropdown"
        className="Select__select"
        style={{ width }}
        onChange={handleChange}
        placeholder={placeholder}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        getPopupContainer={() => ref.current ?? document.body}
        suffixIcon={<Icon name="expand-down" className="Select__arrow" />}>
        {map(data, (item) => {
          return (
            <Option key={`${item.value}-${item.name}`} value={item.value}>
              {mode !== 'multiple' && <Icon name="check" className="Select__check" />}
              {item.name}
              {item?.image && <img src={item?.image} alt="" className="Select__image" />}
            </Option>
          );
        })}
      </ASelect>
    </div>
  );
};

export default Select;
