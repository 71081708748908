/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row, Skeleton } from 'antd';
import classNames from 'classnames';
import Button from 'components/common/Button';
import { Icon } from 'components/common/Icon';
import { Input } from 'components/common/Input';
import Dropdown from 'components/common/Dropdown';
import Select from 'components/common/Select';
import Modal from 'components/common/Modal';
import { Notification } from 'components/common/Notification';
import { Typography } from 'components/common/Typography';
import { SelectValue } from 'antd/lib/select';
import { isEmpty, isEqual, size } from 'lodash';
import { accessControlAPI, propertyAPI } from 'services';
import { getSkeletonsSize, updateSkeletonsSize, validIpAddress } from 'helpers/utils';
import { useAppSelector } from 'store';
import { SectorButton } from 'components/common/SectorButton';
import { useParams } from 'react-router-dom';
import { aclButtonData } from 'views/Project/ProjectLogsView/constants';
import { IAccessControlHostnames, IAccessControResponse } from 'models';
import styles from './ProjectSecurityAccessControl.module.scss';

export interface AcListType {
  ip: string | undefined;
  hostnames: string[] | undefined;
}

export const ProjectSecurityAccessControl: FC = () => {
  const { directoryName } = useParams<{ directoryName?: string }>();
  const { token } = useAppSelector(({ auth }) => auth);
  const { environment_id: envId } = useAppSelector(({ property }) => property);
  const [visible, setVisible] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [editedIndex, setEditedIndex] = useState(0);
  const [editedValue, setEditedValue] = useState('');
  const [editedHostnames, setEditedHostnames] = useState<string[]>();
  const [addedHostnames, setAddedHostnames] = useState<string[]>();
  const [value, setValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [typeName, setTypeName] = useState<'blacklist' | 'whitelist'>('whitelist');
  const [isPolling, setIsPolling] = useState(false);
  const [checkedDataType, setCheckedDataType] = useState(0);
  const [hostnamesValues, setHostnamesValues] = useState<string[] | unknown[]>();
  const [blackListInitialValues, setBlackListInitialValues] = useState<AcListType[]>();
  const [whiteListInitialValues, setWhiteListInitialValues] = useState<AcListType[]>();
  const [blackListValues, setBlackListValues] = useState<AcListType[]>();
  const [whiteListValues, setWhiteListValues] = useState<AcListType[]>();
  const [skeletons] = useState(getSkeletonsSize('acl', String(directoryName)));

  const { data: projectDetails, isLoading: isLoadingEnvId } = propertyAPI.useFetchPropertyQuery(
    String(directoryName),
    {
      skip: !token,
    }
  );

  const {
    data: accessControlList,
    refetch,
    isLoading,
  } = accessControlAPI.useGetControlListQuery(String(projectDetails?.data?.environment_id), {
    skip: !token || !envId || !projectDetails?.data?.environment_id,
    pollingInterval: isPolling ? 3000 : 0,
  });

  useEffect(() => {
    if (accessControlList?.data) {
      updateSkeletonsSize(
        'acl',
        String(directoryName),
        size(size(whiteListValues) > 1 ? [...(whiteListValues ?? [])] : [1])
      );
    }
  }, [accessControlList?.data, blackListValues, directoryName, typeName, whiteListValues]);

  const getAclLists = useCallback((dataACL: IAccessControResponse) => {
    const blacklistMap = new Map();
    const whitelistMap = new Map();
    const allHostnames = new Set();
    const allStatuses: string[] = [];

    dataACL?.data.forEach((item) => {
      const { hostname } = item;
      allHostnames.add(hostname);

      const { status } = item.details;
      const { blacklist, whitelist } = item.details.acl;

      allStatuses.push(status);

      blacklist.forEach((ip) => {
        if (!blacklistMap.has(ip)) {
          blacklistMap.set(ip, new Set());
        }
        blacklistMap.get(ip).add(hostname);
      });

      whitelist.forEach((ip) => {
        if (!whitelistMap.has(ip)) {
          whitelistMap.set(ip, new Set());
        }
        whitelistMap.get(ip).add(hostname);
      });
    });

    const blacklistArray: AcListType[] = Array.from(blacklistMap, ([ip, hostnames]) => ({
      ip,
      hostnames: Array.from(hostnames),
    }));
    const whitelistArray: AcListType[] = Array.from(whitelistMap, ([ip, hostnames]) => ({
      ip,
      hostnames: Array.from(hostnames),
    }));
    const hostnamesArray: string[] | unknown[] | undefined = Array.from(allHostnames);
    const statusesArray: string[] = Array.from(allStatuses);

    return {
      blacklist: blacklistArray,
      whitelist: whitelistArray,
      hostnames: hostnamesArray,
      statuses: statusesArray,
    };
  }, []);

  useEffect(() => {
    if (accessControlList) {
      const { blacklist, whitelist, hostnames, statuses } = getAclLists(accessControlList);
      const hasInProgress = () => statuses.some((item) => item === 'in_progress');

      setIsPolling(hasInProgress);
      setHostnamesValues(hostnames);
      setBlackListInitialValues(blacklist);
      setWhiteListInitialValues(whitelist);
      setBlackListValues(blacklist);
      setWhiteListValues(whitelist);
    }

    // return () => {
    //   if (envId) dispatch(updateEnvId(''));
    // };
  }, [accessControlList, getAclLists, envId, refetch]);

  // const hasInProgress = useMemo(
  //   () => accessControlList?.data?.some((item) => item.details.status === 'in_progress'),
  //   [accessControlList?.data]
  // );

  const [updateControlList, { isLoading: isLoadingUpdate }] =
    accessControlAPI.useUpdateControlListMutation();

  const getCheckedDataTypeName = useMemo(() => {
    switch (checkedDataType) {
      case 0:
        return 'whitelist';
      case 1:
        return 'blacklist';

      default:
        return 'whitelist';
    }
  }, [checkedDataType]);

  useEffect(() => {
    setTypeName(getCheckedDataTypeName);
  }, [getCheckedDataTypeName]);

  const onAddIp = useCallback(() => {
    setVisible(true);
    if ([...(hostnamesValues || [])]?.length === 1)
      setAddedHostnames([...(hostnamesValues || [])] as string[]);
  }, [hostnamesValues]);

  const onOkEdit = useCallback(() => {
    if (typeName === 'whitelist') {
      setWhiteListValues((prev) => {
        return prev?.map((item, idx) => {
          if (idx === editedIndex) {
            return { ...item, ...{ ip: editedValue, hostnames: editedHostnames } };
          }
          return item;
        });
      });
    } else {
      setBlackListValues((prev) => {
        return prev?.map((item, idx) => {
          if (idx === editedIndex) {
            return { ...item, ...{ ip: editedValue, hostnames: editedHostnames } };
          }
          return item;
        });
      });
    }
    setVisibleEdit(false);
    setValue('');
    setEditedHostnames([]);
  }, [editedHostnames, editedIndex, editedValue, typeName]);

  const handleAdd = useCallback(() => {
    if (typeName === 'whitelist') {
      setWhiteListValues((prevState) => [
        ...(prevState || []),
        { ip: value, hostnames: addedHostnames },
      ]);
    } else {
      setBlackListValues((prevState) => [
        ...(prevState || []),
        { ip: value, hostnames: addedHostnames },
      ]);
    }
    setVisible(false);
    setValue('');
    setAddedHostnames([]);
  }, [addedHostnames, typeName, value]);

  const handleEdit = useCallback((item: AcListType, ind: number) => {
    const { ip, hostnames } = item;
    setVisibleEdit(true);
    setEditedIndex(ind);
    setEditedValue(String(ip));
    setEditedHostnames(hostnames);
  }, []);

  const onDelete = useCallback(
    (ind: number) => {
      if (typeName === 'whitelist') {
        setWhiteListValues((prev) => {
          const newList = prev ? [...prev] : [];
          newList.splice(ind, 1);
          return newList;
        });
      } else {
        setBlackListValues((prev) => {
          const newList = prev ? [...prev] : [];
          newList.splice(ind, 1);
          return newList;
        });
      }
    },
    [typeName]
  );

  const isSaveBtnDisabled = useMemo(() => {
    return (
      isEqual(blackListInitialValues, blackListValues) &&
      isEqual(whiteListInitialValues, whiteListValues)
    );
  }, [blackListInitialValues, blackListValues, whiteListInitialValues, whiteListValues]);

  const assemblePayloadData = useCallback(
    ({
      blacklist,
      whitelist,
      hostnames,
    }: {
      blacklist: AcListType[];
      whitelist: AcListType[];
      hostnames: string[];
    }) => {
      const hostnameMap: Record<string, IAccessControlHostnames> = {};

      hostnames.forEach((hostname) => {
        hostnameMap[hostname] = {
          acl: {
            blacklist: [],
            whitelist: [],
          },
          hostname,
        };
      });

      blacklist?.forEach(({ ip, hostnames: hnames }) => {
        hnames?.forEach((hostname) => {
          if (hostnameMap[hostname] && ip) {
            // @ts-ignore
            hostnameMap[hostname].acl.blacklist.push(ip);
          }
        });
      });

      whitelist?.forEach(({ ip, hostnames: hnames }) => {
        hnames?.forEach((hostname) => {
          if (hostnameMap[hostname] && ip) {
            // @ts-ignore
            hostnameMap[hostname].acl.whitelist.push(ip);
          }
        });
      });

      return Object.values(hostnameMap) as IAccessControlHostnames[];
    },
    []
  );

  const handleSaveChanges = useCallback(() => {
    const payloadData = assemblePayloadData({
      blacklist: blackListValues as AcListType[],
      whitelist: whiteListValues as AcListType[],
      hostnames: hostnamesValues as string[],
    });

    updateControlList({ envId, payload: { hostnames: payloadData } })
      .unwrap()
      .then(() => {
        Notification({ type: 'check', title: 'IP was created successfuly!' });
        setVisible(false);
        setValue('');
        refetch();
      })
      .catch((err) => {
        if (err.data?.data) {
          const errorData = Object.values(err.data.data).flat(5) ?? [];

          const errorMessage = (
            <Row>
              {errorData.map((item: any) => (
                <Col span={24} key={item} style={{ margin: '3px 0' }}>
                  <span>{item}</span>
                </Col>
              ))}
            </Row>
          );
          Notification({
            type: 'cross',
            title: err?.data?.message || '',
            message: errorMessage,
          });
        } else {
          Notification({ type: 'cross', title: 'Error', message: err?.data?.message || '' });
        }
      });
  }, [
    assemblePayloadData,
    blackListValues,
    envId,
    hostnamesValues,
    refetch,
    updateControlList,
    whiteListValues,
  ]);

  const currentList = useMemo(() => {
    return typeName === 'whitelist' ? whiteListValues : blackListValues;
  }, [blackListValues, typeName, whiteListValues]);

  const renderDropdownContent = (item: AcListType, index: number) => {
    return (
      <Col className={styles.accessControlDetailsBtns} key={index}>
        <Row align="middle" justify="start">
          <Button
            icon="edit"
            className={styles.additionalBtn}
            type="secondary"
            onClick={() => handleEdit(item, index)}>
            <span>Edit</span>
          </Button>
        </Row>
        <Row style={{ paddingTop: 8 }} />
        <Row align="middle" justify="start">
          <Button
            icon="remove2"
            onClick={() => onDelete(index)}
            className={styles.additionalBtn}
            type="secondary">
            <span>Delete IP</span>
          </Button>
        </Row>
      </Col>
    );
  };

  const onChangeEditedHostnames = (selectValues: SelectValue) => {
    setEditedHostnames(selectValues as string[]);
  };

  const onChangeAddedHostnames = (selectValues: SelectValue) => {
    setAddedHostnames(selectValues as string[]);
  };

  useEffect(() => {
    if ([...(hostnamesValues || [])]?.length === 1)
      setAddedHostnames([...(hostnamesValues || [])] as string[]);
  }, [hostnamesValues]);

  return (
    <>
      <>
        <Modal
          width="340px"
          visible={visibleEdit}
          onCancel={() => setVisibleEdit(false)}
          title="Edit IP"
          okButtonLabel="Add"
          okLoading={isLoadingUpdate}>
          <div className="HomeAddModal">
            <Row style={{ width: '100%' }}>
              <Col span={24}>
                <Input
                  label="IP Address"
                  placeholder="e.g. 192.168.1.100"
                  value={editedValue}
                  onChange={(v: React.FormEvent<HTMLInputElement>) => {
                    setValue(v.currentTarget.value);
                    setEditedValue(v.currentTarget.value);
                  }}
                />
                <Row justify="end">
                  {editedValue.length > 7 && !validIpAddress(editedValue) && (
                    <span style={{ position: 'absolute', color: 'red', fontSize: '10px' }}>
                      Please input a valid IP adress
                    </span>
                  )}
                </Row>
              </Col>
            </Row>
            <Row style={{ width: '100%', paddingTop: 16 }}>
              <Col span={24}>
                {[...(hostnamesValues || [])]?.length > 1 ? (
                  <Select
                    mode="multiple"
                    label="Hostnames"
                    placeholder="Select options"
                    value={editedHostnames}
                    data={[...((hostnamesValues || []) as string[])]?.map((item) => ({
                      value: item,
                      name: item,
                    }))}
                    width="100%"
                    onChange={onChangeEditedHostnames}
                  />
                ) : (
                  <>
                    <Input
                      value={[...(hostnamesValues || [])][0] as string}
                      disabled
                      label="Hostname"
                    />
                  </>
                )}
              </Col>
            </Row>

            <Row justify="space-between" gutter={16} style={{ paddingTop: 16 }}>
              <Col span={12}>
                <Button
                  className={styles.footerButton}
                  type="secondary"
                  onClick={() => setVisibleEdit(false)}>
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  className={styles.footerButton}
                  disabled={!validIpAddress(editedValue) || isEmpty(editedHostnames)}
                  onClick={onOkEdit}
                  type="primary"
                  loading={isLoadingUpdate}>
                  Save
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>

        <Modal
          width="340px"
          visible={visible}
          onCancel={() => setVisible(false)}
          title={`Add IP to ${typeName === 'whitelist' ? 'White List' : ' Black List'}`}
          okButtonLabel="Add"
          okLoading={isLoadingUpdate}>
          <div className="HomeAddModal">
            <Row style={{ width: '100%' }}>
              <Col span={24}>
                <Input
                  label="IP Address"
                  placeholder="e.g. 192.168.1.100"
                  value={value}
                  onChange={(v: React.FormEvent<HTMLInputElement>) => {
                    setValue(v.currentTarget.value);
                  }}
                />
                <Row justify="end">
                  {value.length > 7 && !validIpAddress(value) && (
                    <span style={{ position: 'absolute', color: 'red', fontSize: '10px' }}>
                      Please input a valid IP adress
                    </span>
                  )}
                </Row>
              </Col>
            </Row>
            <Row style={{ width: '100%', paddingTop: 16 }}>
              <Col span={24}>
                {[...(hostnamesValues || [])]?.length > 1 ? (
                  <Select
                    mode="multiple"
                    label="Hostnames"
                    placeholder="Select options"
                    value={addedHostnames}
                    data={[...((hostnamesValues || []) as string[])]?.map((item) => ({
                      value: item,
                      name: item,
                    }))}
                    width="100%"
                    onChange={onChangeAddedHostnames}
                  />
                ) : (
                  <>
                    <Input
                      value={[...(hostnamesValues || [])][0] as string}
                      disabled
                      label="Hostname"
                    />
                  </>
                )}
              </Col>
            </Row>
            <Row justify="space-between" gutter={16} style={{ paddingTop: 16 }}>
              <Col span={12}>
                <Button
                  className={styles.footerButton}
                  type="secondary"
                  onClick={() => {
                    setVisible(false);
                    setValue('');
                  }}>
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  className={styles.footerButton}
                  disabled={!validIpAddress(value) || isEmpty(addedHostnames)}
                  onClick={() => handleAdd()}
                  type="primary"
                  loading={isLoadingUpdate}>
                  <div style={{ width: '100%' }}>Add</div>
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>

        <span className={styles.title}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.{' '}
        </span>

        <div className={styles.head}>
          <Row justify="space-between" align="middle">
            <Row justify="start">
              <Col>
                <SectorButton
                  data={aclButtonData}
                  checked={checkedDataType}
                  onClick={setCheckedDataType}
                />
              </Col>
            </Row>
            <Row gutter={16} justify="end">
              <Col>
                <Row align="middle">
                  <Col>
                    <Input
                      icon="search"
                      value={searchValue}
                      onChange={(v: React.FormEvent<HTMLInputElement>) => {
                        setSearchValue(v.currentTarget.value);
                      }}
                      placeholder="Search"
                      width={260}
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Button disabled={isEmpty(hostnamesValues)} type="primary" onClick={onAddIp}>
                  Add New IP
                </Button>
              </Col>
            </Row>
          </Row>
        </div>
        <Row className={styles.tableHead}>
          <Col span={5} className={styles.headCol}>
            <span className={styles.headLabel}>IP</span>
          </Col>
          <Col span={17} className={styles.headCol}>
            <span className={styles.headLabel}>HOSTNAMES</span>
          </Col>
        </Row>

        {isLoadingEnvId || isLoading ? (
          Array.from({ length: skeletons }).map((i, indx) => (
            <Row key={String(i) + indx} className={styles.item}>
              <Col span={24}>
                <Skeleton.Button active size="small" block />
              </Col>
            </Row>
          ))
        ) : !currentList || isEmpty(currentList) ? (
          <Row key={0} className={styles.item}>
            <Typography>
              {`IP ${typeName === 'whitelist' ? 'White List' : ' Black List'} is empty`}
            </Typography>
          </Row>
        ) : (
          [...(currentList || [])]
            .filter((item) => item?.ip?.includes(searchValue))
            .map((item, indx) => (
              <Row key={item.ip + String(indx)} className={styles.item}>
                <Col span={5} className={styles.col}>
                  <span className={styles.tableLabel}>{item.ip}</span>
                </Col>
                <Col span={17} className={styles.col} style={{ display: 'flex' }}>
                  <span className={styles.tableLabelHostnames}>
                    {[...(item.hostnames || [])].join(', ')}
                  </span>
                </Col>
                <Col span={2} className={classNames(styles.col, styles.rightAlign)}>
                  <Dropdown
                    trigger={['click']}
                    className="moreDetails"
                    align={{ targetOffset: [25, 10] }}
                    placement="bottomLeft"
                    triggerElement={<Icon name="action-vertical" className={styles.dotsIcon} />}>
                    {renderDropdownContent(item, indx)}
                  </Dropdown>
                </Col>
              </Row>
            ))
        )}

        {!currentList ||
          (!isEmpty(currentList) && (
            <Row style={{ paddingTop: 20 }}>
              <Col>
                <Button
                  disabled={isSaveBtnDisabled || isPolling}
                  loading={isLoadingUpdate}
                  type="primary"
                  onClick={handleSaveChanges}>
                  {isPolling ? 'Deploying...' : ' Save Changes'}
                </Button>
              </Col>
            </Row>
          ))}
      </>
    </>
  );
};

export default ProjectSecurityAccessControl;
